import { createSlice } from '@reduxjs/toolkit';

import { paymentLineGetByBooking } from '@crac/core/redux/actions/PaymentLineActions';
import type { IPaymentLineReducerState } from '@crac/core/redux-store/reducersState/paymentLine';

const initialState: IPaymentLineReducerState = {
	paymentLines: [],
	paymentLinesRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const paymentLinesSlice = createSlice({
	name: 'PaymentLinesSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// GET BY BOOKING
		builder
			.addCase(paymentLineGetByBooking.pending, (state) => {
				return {
					...state,
					paymentLinesRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(paymentLineGetByBooking.fulfilled, (state, action) => {
				return {
					...state,
					paymentLines: action.payload,
					paymentLinesRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(paymentLineGetByBooking.rejected, (state, action) => {
				return {
					...state,
					paymentLinesRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const paymentLinesReducer = paymentLinesSlice.reducer;
