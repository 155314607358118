import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { bookingSearch, bookingSearchReset } from '@crac/core/redux/actions/BookingActions';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import type { IBookingSearchReducerState } from '@crac/core/redux-store/reducersState/booking/SearchReducerState';
const initialState: IBookingSearchReducerState = {
	bookingSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	bookings: [],
};

const bookingSearchSlice = createSlice({
	name: 'BookingSearchSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// SEARCH
		builder
			.addCase(bookingSearch.pending, (state) => {
				return {
					...state,
					bookingSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
					bookings: [],
				};
			})
			.addCase(bookingSearch.fulfilled, (state, action) => {
				return {
					...state,
					bookingSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					bookings: action.payload,
				};
			})
			.addCase(bookingSearch.rejected, (state, action) => {
				return {
					...state,
					bookingSearchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CLEAR CACHE & RESET
		builder.addMatcher(isAnyOf(clearGlobalCache, bookingSearchReset), () => {
			return initialState;
		});
	},
});

export const bookingSearchReducer = bookingSearchSlice.reducer;
