import { i18n } from '@lingui/core';
// import { en, es } from 'make-plural/plurals';

export const locales = {
	en: 'es',
	es: 'es',
};
export const defaultLocale = 'es';

/*
 * i18n.loadLocaleData({
 * 	en: { plurals: en },
 * 	es: { plurals: es },
 * });
 */

/**
 * We do a dynamic import of just the catalog that we need
 * @param locale any locale string
 */
export const dynamicActivate = async (locale: string) => {
	const esMessages = await import(`../assets/locales/es/messages`);
	const enMessages = await import(`../assets/locales/en/messages`);

	i18n.load({
		en: enMessages.messages,
		es: esMessages.messages,
	});

	i18n.activate(locale || defaultLocale);
};
