import type { FC } from 'react';
import React from 'react';

import type { IBrandType } from '.';

interface INavbarBrandImg {
	brand?: IBrandType;
	classBrand: string;
	key: number;
}

export const navbarBrandImg: FC<INavbarBrandImg> = ({ classBrand, key, brand }) => {
	return (
		<img
			alt={brand && brand.alt ? brand.alt : ''}
			className={classBrand}
			height={brand && brand.height ? brand.height : '100%'}
			key={key.toString()}
			src={brand && brand.src ? brand.src : ''}
			width={brand && brand.width ? brand.width : 'auto'}
		/>
	);
};
