export enum CommissionEndPoints {
	/**
	 * Commission get by vendorCode, dateFrom and dateUntil
	 * @host `/commission/getbyvendor/`
	 */
	GET_BY_VENDOR = '/commission/getbyvendor/',
	/**
	 * Commission get grouped by month
	 * @host `/commission/getGroupByMonth/`
	 */
	GET_GROUP_BY_MONTH = '/commission/getGroupByMonth/',
}
