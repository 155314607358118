import { createAction } from '@reduxjs/toolkit';

import { commissionServiceGetByVendor, commissionServiceGetGroupByMonth } from '../../data/services/CommissionService';
import type { ICommission, ICommissionAmountByMount } from '../../models/entities/Commission';
import type { ICommissionGetByVendorParams } from '../../models/serviceParams/CommissionParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Retrieves commissions associated with a specific vendor.
 *
 * @param {ICommission[]} payload - The commissions to be retrieved.
 * @param {ICommissionGetByVendorParams} params - Parameters to identify commissions related to a specific vendor.
 * @returns {Promise<void>} - A promise that resolves to an array of commissions.
 */
export const commissionGetByVendor = createAsyncAction<ICommission[], ICommissionGetByVendorParams>(
	'commission/getByVendor',
	commissionServiceGetByVendor,
);

/**
 * Retrieves grouped commission amounts by month.
 *
 * @param {ICommissionAmountByMount[]} payload - The commission amounts grouped by month.
 * @param {ICommissionGetByVendorParams} params - Parameters used for grouping the commissions by month.
 * @returns {Promise<void>} - A promise that resolves to an array of commission amounts grouped by month.
 */
export const commissionGetGroupByMonth = createAsyncAction<ICommissionAmountByMount[], ICommissionGetByVendorParams>(
	'commission/getGroupByMonth',
	commissionServiceGetGroupByMonth,
);

/**
 * Resets the commission search state.
 */
export const commissionResetSearch = createAction('commission/resetSearch');
