import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import { customerClear, customerGetByCode } from '@crac/core/redux/actions/CustomerActions';
import type { ICustomerDetailReducerState } from '@crac/core/redux-store/reducersState/customer/DetailReducerState';

const initialState: ICustomerDetailReducerState = {
	customer: null,
	customerGetByCodeRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const customerSlice = createSlice({
	name: 'CustomerSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// GET BY CODE
		builder
			.addCase(customerGetByCode.pending, (state) => {
				return {
					...state,
					customerGetByCodeRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerGetByCode.fulfilled, (state, action) => {
				return {
					...state,
					customer: action.payload,
					customerGetByCodeRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerGetByCode.rejected, (state, action) => {
				return {
					...state,
					customerGetByCodeRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CLEAR CACHE & RESET
		builder.addMatcher(isAnyOf(clearGlobalCache, customerClear), () => {
			return initialState;
		});
	},
});

export const customerReducer = customerSlice.reducer;
