import { combineReducers } from '@reduxjs/toolkit';

import { availabilityReducer as Availability } from './Availability';
import { bookingDetailReducer as Detail } from './Detail';
import { documentReducer as Document } from './Document';
import { extendReducer as Extend } from './Extend';
import { extraDriverReducer as ExtraDriver } from './ExtraDriver';
import { bookingInsertExternalReducer as Insert } from './Insert';
import { bookingLinesReducer as Lines } from './Lines';
import { bookingNewReducer as NewBooking } from './New';
import { paymentLinesReducer as PaymentLines } from './PaymentLines';
import { bookingSearchReducer as Search } from './Search';

export const bookingReducers = combineReducers({
	Availability,
	Detail,
	Document,
	Extend,
	ExtraDriver,
	Insert,
	Lines,
	NewBooking,
	PaymentLines,
	Search,
});
