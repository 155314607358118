import type { IPartner } from '@crac/core/models/entities/Partner';
import type { IProvider } from '@crac/core/models/entities/Provider';
import { PartnerType } from '@crac/core/models/types/PartnerType';
import type { IBookingNewReducerState } from '@crac/core/redux-store/reducersState/booking/BookingNewReducerState';
import type { ICompanyDetailReducerState } from '@crac/core/redux-store/reducersState/company/DetailReducerState';

import { useAppSelector } from '~/redux/hooks';

export const usePartnerSelector = (): IPartner => useAppSelector((state) => state.Partner.employee as IPartner);
export const useIsPartnerVendor = (): boolean =>
	useAppSelector((state) => (state.Partner.employee as IPartner).partnerType === PartnerType.vendor);
export const useProvidersSelector = (): IProvider[] => useAppSelector((state) => state.Provider.providers);
export const useLocaleSelector = (): string => useAppSelector((state) => state.Commons.Locale.locale);
export const useNewBookingSelector = (): IBookingNewReducerState => useAppSelector((state) => state.Booking.NewBooking);

export const useCompanyStateSelector = (): ICompanyDetailReducerState => useAppSelector((state) => state.Company);
