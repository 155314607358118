import {
	cardLinkService,
	cardLinkServiceCallBack,
	cardServiceGetByCustomer,
	cardServiceSetPreferred,
	cardServiceUnlink,
} from '../../data/services/CardService';
import type { ICard } from '../../models/entities/Card';
import type {
	ICardGetByCustomerParams,
	ICardSetPreferredParams,
	ILinkCardParams,
	IUnlinkCardParams,
} from '../../models/serviceParams/CardParams';
import type { IPaymentCallbackParams } from '../../models/serviceParams/PaymentParams';
import type { ILinkCardResponse } from '../../models/serviceResponse/LinkCardResponse';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Retrieves cards associated with a specific customer.
 *
 * @param {ICard[]} payload - The cards to be retrieved.
 * @param {ICardGetByCustomerParams} params - Parameters to identify the customer whose cards are to be retrieved.
 * @returns {Promise<void>} - A promise that resolves to an array of cards for the specified customer.
 */
export const cardGetByCustomer = createAsyncAction<ICard[], ICardGetByCustomerParams>(
	'card/getByCustomer',
	cardServiceGetByCustomer,
);

/**
 * Unlinks a card from the customer's account.
 *
 * @param {boolean} payload - The result of the unlinking operation.
 * @param {IUnlinkCardParams} params - Parameters for the card to be unlinked.
 * @returns {Promise<void>} - A promise that resolves after the card is unlinked.
 */
export const cardUnlink = createAsyncAction<boolean, IUnlinkCardParams>('card/unlink', cardServiceUnlink);

/**
 * Sets a specific card as the preferred card for the customer.
 *
 * @param {ICard} payload - The card to be set as preferred.
 * @param {ICardSetPreferredParams} params - Parameters for setting the card as preferred.
 * @returns {Promise<void>} - A promise that resolves after the card is set as preferred.
 */
export const cardSetPreferred = createAsyncAction<ICard, ICardSetPreferredParams>(
	'card/setPreferred',
	cardServiceSetPreferred,
);

/**
 * Links a new card to the customer's account.
 *
 * @param {ILinkCardResponse} payload - The response after linking the card.
 * @param {ILinkCardParams} params - Parameters for linking the new card.
 * @returns {Promise<void>} - A promise that resolves with the details of the linked card.
 */
export const cardLink = createAsyncAction<ILinkCardResponse, ILinkCardParams>('card/link', cardLinkService);

/**
 * Handles the callback from a payment gateway during the card linking process.
 *
 * @param {ILinkCardResponse} payload - The response from the payment gateway.
 * @param {IPaymentCallbackParams} params - Parameters from the payment callback.
 * @returns {Promise<void>} - A promise that resolves with the outcome of the callback process.
 */
export const cardLinkCallBack = createAsyncAction<ILinkCardResponse, IPaymentCallbackParams>(
	'card/linkCallBack',
	cardLinkServiceCallBack,
);
