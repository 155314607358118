import React from 'react';

import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from './routesIdMap';

const Cards = React.lazy(() => import('~/pages/Cards'));

export const cardsRoutes: { [key: number]: IRouteConfig } = {};

cardsRoutes[routesIdMap.Cards] = {
	component: Cards,
	exact: true,
	id: routesIdMap.Cards,
	name: 'Cards',
	path: '/cards',
	permissions: [PermissionsType.BookingNew],
};
