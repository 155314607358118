import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import {
	commissionGetByVendor,
	commissionGetGroupByMonth,
	commissionResetSearch,
} from '@crac/core/redux/actions/CommissionActions';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import type { ICommissionReducerState } from '@crac/core/redux-store/reducersState/commission';

const initialState: ICommissionReducerState = {
	commissions: {
		data: [],
		inProgress: false,
		messages: [],
		ok: true,
	},
	commissionsByMonth: {
		data: [],
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const commissionsSlice = createSlice({
	name: 'CommissionsSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// GET BY VENDOR
		builder
			.addCase(commissionGetByVendor.pending, (state) => {
				return {
					...initialState,
					commissions: {
						data: state.commissions.data,
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(commissionGetByVendor.fulfilled, (state, { payload }) => {
				return {
					...state,
					commissions: {
						data: payload,
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(commissionGetByVendor.rejected, (state, { payload }) => {
				return {
					...state,
					commissions: {
						data: [],
						inProgress: false,
						messages: payload || [],
						ok: false,
					},
				};
			});
		// GET GROUP BY MONTH
		builder
			.addCase(commissionGetGroupByMonth.pending, (state) => {
				return {
					...state,
					commissionsByMonth: {
						data: state.commissionsByMonth.data,
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(commissionGetGroupByMonth.fulfilled, (state, { payload }) => {
				return {
					...state,
					commissionsByMonth: {
						data: payload,
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(commissionGetGroupByMonth.rejected, (state, { payload }) => {
				return {
					...state,
					commissionsByMonth: {
						data: [],
						inProgress: false,
						messages: payload || [],
						ok: false,
					},
				};
			});
		// CLEAR CACHE & RESET
		builder.addMatcher(isAnyOf(clearGlobalCache, commissionResetSearch), () => {
			return initialState;
		});
	},
});

export const commissionsReducer = commissionsSlice.reducer;
