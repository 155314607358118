import { createSlice } from '@reduxjs/toolkit';

import { documentGetByBooking } from '@crac/core/redux/actions/DocumentActions';
import type { IDocumentReducerState } from '@crac/core/redux-store/reducersState/booking/DocumentReducerState';

const initialState: IDocumentReducerState = {
	documents: [],
	documentsRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const documentSlice = createSlice({
	name: 'DocumentSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// GET BY BOOKING
		builder
			.addCase(documentGetByBooking.pending, (state) => {
				return {
					...state,
					documents: [],
					documentsRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(documentGetByBooking.fulfilled, (state, action) => {
				return {
					...state,
					documents: action.payload,
					documentsRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(documentGetByBooking.rejected, (state, action) => {
				return {
					...state,
					documents: [],
					documentsRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const documentReducer = documentSlice.reducer;
