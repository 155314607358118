import React, { useEffect } from 'react';

import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';

import { useLocaleSelector } from '~/hooks/useStateSelectors';

import { defaultLocale, dynamicActivate } from './i18n';

export const I18nContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const currentLocale = useLocaleSelector();

	useEffect(() => {
		dynamicActivate(currentLocale || defaultLocale);
	}, [currentLocale]);

	return <I18nProvider i18n={i18n}>{children}</I18nProvider>;
};
