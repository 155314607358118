import type { ICommission, ICommissionAmountByMount } from '../../models/entities/Commission';
import type { ICommissionGetByVendorParams } from '../../models/serviceParams/CommissionParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { CommissionEndPoints } from '../endPoints/CommissionEndPoints';

/**
 * Get commisssions
 *  {string} code vendor code
 *  {DateTime} code dateFrom
 *  {DateTime} code dateUntil
 * @return {Promise} `VendorPropType`
 */
export const commissionServiceGetByVendor = (
	model: ICommissionGetByVendorParams,
): Promise<ServiceResponse<ICommission[]>> => {
	return Api.get<ICommission[], ICommissionGetByVendorParams>(CommissionEndPoints.GET_BY_VENDOR, model);
};

export const commissionServiceGetGroupByMonth = (
	model: ICommissionGetByVendorParams,
): Promise<ServiceResponse<ICommissionAmountByMount[]>> => {
	return Api.get<ICommissionAmountByMount[], ICommissionGetByVendorParams>(
		CommissionEndPoints.GET_GROUP_BY_MONTH,
		model,
	);
};
