import { createSlice } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { bookingLineGetByBooking } from '@crac/core/redux/actions/BookingLineActions';
import type { ILinesReducerState } from '@crac/core/redux-store/reducersState/booking/LinesReducerState';

const initialState: ILinesReducerState = {
	bookingLines: [],
	bookingLinesRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	bookingLinesToChangeInvoiceTo: [],
};

const bookingLinesSlice = createSlice({
	name: 'BookingLinesSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// GET BY BOOKING
		builder
			.addCase(bookingLineGetByBooking.pending, (state) => {
				return {
					...state,
					bookingLinesRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingLineGetByBooking.fulfilled, (state, action) => {
				return {
					...state,
					bookingLines: deepCopy(action.payload),
					bookingLinesRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingLineGetByBooking.rejected, (state, action) => {
				return {
					...state,
					bookingLinesRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
	},
});

export const bookingLinesReducer = bookingLinesSlice.reducer;
