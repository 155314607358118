import type { FC } from 'react';
import React from 'react';

import classNames from 'classnames';

import { navbarBrandImg } from './NavbarBrandImg';

export interface IBrandType {
	alt?: string;
	height?: number;
	src?: string;
	width?: number;
}

export interface INavbarBrandProps {
	brand?: IBrandType;
	className?: string;
	full?: IBrandType;
	minimized?: IBrandType;
	children?: React.ReactNode;
}

export const NavBarBrand: FC<INavbarBrandProps> = ({ brand, children, className, full, minimized, ...attributes }) => {
	const classes = classNames(className, 'navbar-brand');

	const img = [];
	if (brand) {
		const classBrand = 'navbar-brand';
		img.push(navbarBrandImg({ brand, classBrand, key: img.length + 1 }));
	}

	if (full) {
		const classBrand = 'navbar-brand-full';
		img.push(navbarBrandImg({ brand: full, classBrand, key: img.length + 1 }));
	}

	if (minimized) {
		const classBrand = 'navbar-brand-minimized';
		img.push(navbarBrandImg({ brand: minimized, classBrand, key: img.length + 1 }));
		// Minimized, classBrand, img.length + 1
	}

	return (
		<a {...attributes} className={classes}>
			{children || img}
		</a>
	);
};
