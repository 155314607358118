import React from 'react';

import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from './routesIdMap';

const BookingSearch = React.lazy(() => import('~/pages/Booking/BookingSearch'));
const BookingDetailFines = React.lazy(() => import('~/pages/Booking/Detail/BookingDetailFines'));
const BookingDetailExcess = React.lazy(() => import('~/pages/Booking/Detail/BookingDetailExcess'));
const BookingDetailVehicles = React.lazy(() => import('~/pages/Booking/Detail/BookingDetailVehicles'));
const BookingDetailDrivers = React.lazy(() => import('~/pages/Booking/Detail/BookingDetailDrivers'));
const BookingDetailInfo = React.lazy(() => import('~/pages/Booking/Detail/BookingDetailInfo'));
const BookingDetailDocuments = React.lazy(() => import('~/pages/Booking/Detail/BookingDetailDocuments'));
const BookingDetailExtend = React.lazy(() => import('~/pages/Booking/Detail/BookingDetailExtendAvailability'));
const BookingDetailExtendConfirm = React.lazy(() => import('~/pages/Booking/Detail/BookingDetailExtendConfirm'));
const BookingNewConfirmation = React.lazy(() => import('../../pages/Booking/New/BookingNewConfirmation'));
const BookingNewInvoiceTo = React.lazy(() => import('../../pages/Booking/New/BookingNewInvoiceTo'));
const BookingNewServices = React.lazy(() => import('../../pages/Booking/New/BookingNewServices'));
const BookingNewGroups = React.lazy(() => import('../../pages/Booking/New/BookingNewGroups'));
const BookingsNewAvailability = React.lazy(() => import('~/pages/Booking/New/BookingNewAvailability'));
const Commissions = React.lazy(() => import('~/pages/Commissions'));

export const bookingRoutes: { [key: number]: IRouteConfig } = {};

bookingRoutes[routesIdMap.BookingsSearch] = {
	component: BookingSearch,
	exact: true,
	id: routesIdMap.BookingsSearch,
	name: 'Search',
	path: '/bookings/search',
	permissions: [PermissionsType.Booking, PermissionsType.BookingSearch],
};

bookingRoutes[routesIdMap.BookingsNewAvailability] = {
	component: BookingsNewAvailability,
	exact: true,
	id: routesIdMap.BookingsNewAvailability,
	name: 'Select car',
	path: '/bookings/new',
	permissions: [PermissionsType.Booking, PermissionsType.BookingNew],
};

bookingRoutes[routesIdMap.BookingsNewGroups] = {
	component: BookingNewGroups,
	exact: true,
	id: routesIdMap.BookingsNewGroups,
	name: 'Select car',
	path: '/bookings/new/groups',
	permissions: [PermissionsType.Booking, PermissionsType.BookingNew],
};

bookingRoutes[routesIdMap.BookingsNewServices] = {
	component: BookingNewServices,
	exact: true,
	id: routesIdMap.BookingsNewServices,
	name: 'Services',
	path: '/bookings/new/services',
	permissions: [PermissionsType.Booking, PermissionsType.BookingNew],
};

bookingRoutes[routesIdMap.BookingsNewInvoiceTo] = {
	component: BookingNewInvoiceTo,
	exact: true,
	id: routesIdMap.BookingsNewInvoiceTo,
	name: 'Invoice to',
	path: '/bookings/new/invoice-to',
	permissions: [PermissionsType.Booking, PermissionsType.BookingNew],
};

bookingRoutes[routesIdMap.BookingsNewConfirmation] = {
	component: BookingNewConfirmation,
	exact: true,
	id: routesIdMap.BookingsNewConfirmation,
	name: 'Customer data and confirmation',
	path: '/bookings/new/confirmation',
	permissions: [PermissionsType.Booking, PermissionsType.BookingNew],
};

/*
 * BOOKING DETAIL
 * --------------------------------------------------------------
 */
bookingRoutes[routesIdMap.BookingsDetail] = {
	component: BookingDetailInfo,
	exact: true,
	id: routesIdMap.BookingsDetail,
	name: 'Detail',
	path: '/bookings/detail/:id',
	permissions: [PermissionsType.Booking, PermissionsType.Booking, PermissionsType.BookingDetail],
};

bookingRoutes[routesIdMap.BookingsDetailExtend] = {
	component: BookingDetailExtend,
	exact: true,
	id: routesIdMap.BookingsDetailExtend,
	name: 'Extend',
	path: '/bookings/detail/:id/extend',
	permissions: [PermissionsType.Booking, PermissionsType.BookingCanExtend],
};

bookingRoutes[routesIdMap.BookingsDetailExtendConfirm] = {
	component: BookingDetailExtendConfirm,
	exact: true,
	id: routesIdMap.BookingsDetailExtendConfirm,
	name: 'Extend',
	path: '/bookings/detail/:id/extend/confirm',
	permissions: [PermissionsType.Booking, PermissionsType.BookingCanExtend],
};

bookingRoutes[routesIdMap.BookingsDetailDocuments] = {
	component: BookingDetailDocuments,
	exact: true,
	id: routesIdMap.BookingsDetailDocuments,
	name: 'Documents',
	path: '/bookings/detail/:id/documents',
	permissions: [PermissionsType.Booking, PermissionsType.BookingDetail],
};

bookingRoutes[routesIdMap.BookingsDetailDrivers] = {
	component: BookingDetailDrivers,
	exact: true,
	id: routesIdMap.BookingsDetailDrivers,
	name: 'Extra drivers',
	path: '/bookings/detail/:id/extra-drivers',
	permissions: [PermissionsType.Booking, PermissionsType.BookingDetail],
};

bookingRoutes[routesIdMap.BookingsDetailVehicles] = {
	component: BookingDetailVehicles,
	exact: true,
	id: routesIdMap.BookingsDetailVehicles,
	name: 'Changes vehicle',
	path: '/bookings/detail/:id/changes-vehicle',
	permissions: [PermissionsType.Booking, PermissionsType.BookingDetail],
};

bookingRoutes[routesIdMap.BookingsDetailExcess] = {
	component: BookingDetailExcess,
	exact: true,
	id: routesIdMap.BookingsDetailExcess,
	name: 'Excess',
	path: '/bookings/detail/:id/excess',
	permissions: [PermissionsType.Booking, PermissionsType.BookingDetail],
};

bookingRoutes[routesIdMap.BookingsDetailFines] = {
	component: BookingDetailFines,
	exact: false,
	id: routesIdMap.BookingsDetailFines,
	name: 'Fines',
	path: '/bookings/detail/:id/fines',
	permissions: [PermissionsType.Booking, PermissionsType.BookingDetail],
};

bookingRoutes[routesIdMap.BookingsDetailFinesDetail] = {
	component: BookingDetailFines,
	exact: true,
	id: routesIdMap.BookingsDetailFinesDetail,
	name: 'Fines',
	path: '/bookings/detail/:id/fines/:fineId/detail',
	permissions: [PermissionsType.Booking, PermissionsType.BookingDetail],
};

/*
 * BOOKING COMMISSIONS
 * --------------------------------------------------------------
 */
bookingRoutes[routesIdMap.Commissions] = {
	component: Commissions,
	exact: true,
	id: routesIdMap.Commissions,
	name: 'Commissions',
	path: '/commissions',
	permissions: [PermissionsType.Commissions],
};
