import { useEffect, useRef, useState } from 'react';

import type { MessageType } from '@crac/core/models/types/MessageType';
import type { IRequestState } from '@crac/core/modules/shared/types/RequestStateType';

import { gCSwal } from '../external/sweet-alert';

interface IUseManageRequestState {
	componentMount: boolean;
	messages: MessageType[];
	ok: boolean;
	inProgress: boolean;
}

export const useManageRequestState = (
	requestState?: IRequestState,
	successCallBack?: () => void,
	callBackError?: (errors: MessageType[]) => void,
): IUseManageRequestState | null => {
	const componentMount = useRef(false);
	const [request, setRequest] = useState(requestState);

	const showError = async (messages: MessageType[]) => {
		await gCSwal('Error!', messages.map((msg) => msg.message).join(', '));
	};

	const handleCheckRequestState = (updateRequest: IRequestState): void => {
		setRequest(updateRequest);
		if (componentMount) {
			if (!updateRequest.inProgress && updateRequest.ok) {
				if (successCallBack) {
					successCallBack();
				}
			}

			if (!updateRequest.inProgress && !updateRequest.ok) {
				if (callBackError) {
					callBackError(updateRequest.messages);
				} else {
					showError(updateRequest.messages);
				}
			}
		}
	};

	useEffect(() => {
		componentMount.current = true;

		return () => {
			componentMount.current = false;
		};
	}, []);

	useEffect(() => {
		if (requestState) {
			handleCheckRequestState(requestState);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [requestState]);

	if (request) {
		return {
			componentMount: componentMount.current,
			inProgress: request.inProgress,
			messages: request.messages,
			ok: request.ok,
		};
	}

	return null;
};
