import { createSlice } from '@reduxjs/toolkit';

import { reducerStorage } from '@crac/core/helpers/reducerStorage';
import type { IAgency } from '@crac/core/models/entities/Agency';
import { agencyGetByCode } from '@crac/core/redux/actions/AgencyActions';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import type { IAgencyReducerState } from '@crac/core/redux-store/reducersState/agency';

const initialState: IAgencyReducerState = {
	agencies: [],
	agency: null,
	agencyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const { removeStorage, updateStorage, getStorage } = reducerStorage<IAgency | null>('agency', null, 'localStorage');

const agencySlice = createSlice({
	name: 'AgencySlice',
	initialState: {
		...initialState,
		agency: getStorage(),
	},
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR CACHE
		builder.addCase(clearGlobalCache, () => {
			removeStorage();
			return initialState;
		});
		// GET BY CODE
		builder
			.addCase(agencyGetByCode.pending, (state) => {
				return {
					...state,
					agency: null,
					agencyRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(agencyGetByCode.fulfilled, (state, action) => {
				return {
					...state,
					agency: updateStorage(null, action.payload),
					agencyRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(agencyGetByCode.rejected, (state, action) => {
				return {
					...state,
					agencyRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const agencyReducer = agencySlice.reducer;
