import { t } from '@lingui/macro';

import type { INavItem } from '@crac/components/intranet/shared/layout/sidebar/types';
import type { IPartner } from '@crac/core/models/entities/Partner';
import type { IProvider } from '@crac/core/models/entities/Provider';
import { PermissionsType } from '@crac/core/models/types/PermissionsType';

export const navigationItems = (partner: IPartner, providers: IProvider[], permissions: string[]): INavItem[] => {
	const [provider] = providers;

	const items: INavItem[] = [
		{
			icon: 'nav-icon fa fa-fw fa-search',
			name: t`Booking search`,
			roles: [PermissionsType.BookingSearch],
			url: '/bookings/search',
		},
		{
			icon: 'nav-icon fa fa-file-o fa-fw',
			name: t`Booking new`,
			roles: [PermissionsType.BookingNew],
			url: '/bookings/new',
		},
		{
			icon: 'nav-icon fa fa-eur fa-fw',
			name: t`Commissions`,
			roles: [PermissionsType.Commissions],
			url: '/commissions',
		},
		{
			icon: 'nav-icon fa fa-fw fa-bar-chart',
			name: t`Commissions Chart`,
			roles: [PermissionsType.Commissions],
			url: '/dashboard',
		},
	];

	if (provider && provider.partnerPrepaidPaymentTerm === partner.paymentTermsCode) {
		items.push({
			icon: 'nav-icon fa fa-fw fa-credit-card',
			name: t`Cards`,
			roles: [PermissionsType.BookingNew],
			url: '/cards',
		});
	}

	const itemsFiltered: INavItem[] = [];

	items.forEach((item) => {
		const { hasPermission } = item.roles.reduce(
			(acc, current) => {
				if (permissions.includes(current)) {
					acc.hasPermission = true;
				}
				return acc;
			},
			{ hasPermission: false } as { hasPermission: boolean },
		);
		if (hasPermission) {
			itemsFiltered.push(item);
		}
	});

	return itemsFiltered;
};
