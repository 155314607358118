import { createSlice } from '@reduxjs/toolkit';

import { reducerStorage } from '@crac/core/helpers/reducerStorage';
import type { IProvider } from '@crac/core/models/entities/Provider';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import { providerGetAll, providerSetCurrent } from '@crac/core/redux/actions/ProviderActions';
import type { IProviderReducerState } from '@crac/core/redux-store/reducersState/provider';

const initialState: IProviderReducerState = {
	current: null,
	provider: null,
	providerGetByBranchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	providers: [],
	providersRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const { getStorage, removeStorage, updateStorage } = reducerStorage<IProvider[]>('providers', []);

const providerSlice = createSlice({
	name: 'ProviderSlice',
	initialState: {
		...initialState,
		providers: getStorage(),
	},
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR CACHE
		builder.addCase(clearGlobalCache, () => {
			removeStorage();
			return initialState;
		});
		// GET ALL
		builder
			.addCase(providerGetAll.pending, () => {
				return initialState;
			})
			.addCase(providerGetAll.fulfilled, (state, action) => {
				return {
					...state,
					current: null,
					providers: updateStorage([], action.payload),
					providersRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(providerGetAll.rejected, (state, action) => {
				return {
					...state,
					providersRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// SET CURRENT
		builder.addCase(providerSetCurrent, (state, action) => {
			return {
				...state,
				current: action.payload,
			};
		});
	},
});

export const providerReducer = providerSlice.reducer;
