import type { FC } from 'react';
import React, { useState } from 'react';

import { Trans } from '@lingui/macro';
import { Link } from 'react-router-dom';

import { Dropdown, DropdownMenu, DropdownToggle } from '@crac/components/external/reactstrap/dropdown';
import type { IPartner } from '@crac/core/models/entities/Partner';

interface IHeaderDropdownProps {
	partner: IPartner;
}

export const HeaderDropdown: FC<IHeaderDropdownProps> = ({ partner }) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const toggle = (): void => {
		setDropdownOpen(!dropdownOpen);
	};

	return (
		<Dropdown isOpen={dropdownOpen} nav tag="div" toggle={toggle}>
			<DropdownToggle nav>
				<strong>
					<i className="fa fa-fw fa-user-o" />{' '}
					<span className="d-none d-sm-inline-block">
						{`${partner.name}(${partner.userName})`.toUpperCase()}
					</span>
				</strong>
			</DropdownToggle>
			<DropdownMenu>
				<Link className="dropdown-item" to="/login">
					<i className="fa fa-fw fa-sign-out" /> <Trans>Logout</Trans>
				</Link>
			</DropdownMenu>
		</Dropdown>
	);
};
