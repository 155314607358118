import React, { Suspense } from 'react';

import { Navigate, Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import { DevelopToolbar } from '@crac/components/intranet/shared/developToolbar';
import { ComponentConsumersType } from '@crac/core/models/types/ComponentConsumersType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routes } from '~/config/routes';
import AutoLogin from '~/pages/Login/AutoLogin';

import { PrivateRoute } from './PrivateRoute';
import { I18nContextProvider } from '../contexts/I18nContextProvider';
import Login from '../pages/Login';

export const App = (): JSX.Element => {
	const routesIds = Object.keys(routes).map((key) => Number(key));

	const router = createBrowserRouter(
		createRoutesFromElements(
			<>
				<Route element={<PrivateRoute />} path="/">
					<Route element={<Navigate to="/dashboard" />} path="/*" />
					<Route element={<Navigate to="/dashboard" />} path="/" />
					{routesIds.map((key) => {
						const route: IRouteConfig = routes[key];
						if (!route) {
							return null;
						}

						const Component = route.component;

						if (!Component) {
							return null;
						}

						return (
							<Route
								element={
									<Suspense fallback={null}>
										<Component />
									</Suspense>
								}
								key={route.id}
								path={route.path}
							/>
						);
					})}
				</Route>
				<Route element={<Login />} path="/login" />
				<Route element={<AutoLogin />} path="/autologin/:type/:id" />
				<Route element={<Navigate to="/login" />} path="*" />
			</>,
		),
	);

	return (
		<I18nContextProvider>
			<DevelopToolbar consumer={ComponentConsumersType.PARTNER} portalId="top-page-content" />
			<RouterProvider router={router} />
		</I18nContextProvider>
	);
};
