import { createSlice } from '@reduxjs/toolkit';

import { managerLocalStorage } from '@crac/core/helpers/reducerStorage';
import { setLocale } from '@crac/core/redux/actions/CommonActions';
import type { ILocaleReduceState } from '@crac/core/redux-store/reducersState/common';

const { getItem, setItem } = managerLocalStorage();
const LOCALE_STORAGE_KEY = 'locale';
const initialState: ILocaleReduceState = {
	locale: getItem('locale') || 'es',
};

const localeSlice = createSlice({
	name: 'LocaleSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// ACTIVE
		builder.addCase(setLocale, (state, { payload }) => {
			setItem(LOCALE_STORAGE_KEY, payload);
			return { locale: payload };
		});
	},
});

export const localeReducer = localeSlice.reducer;
