/* eslint-disable react/require-optimization */
import type { FC } from 'react';
import React from 'react';

import { NavbarToggler } from '@crac/components/external/reactstrap/navbar';
import { NavBarBrand } from '@crac/components/intranet/shared/layout/navbarBrand';
import type { IPartner } from '@crac/core/models/entities/Partner';

import sygnet from '~/assets/img/gc-logo-symbol.png';
import logo from '~/assets/img/logo.svg';

import { HeaderDropdown } from './HeaderDropdown';
import { LanguageSwitch } from './LanguageSwtch';
import { HeaderNav } from './styles';

interface IHeaderProps {
	partner: IPartner;
	locale?: string;
	setLocale?: (locale: string) => void;
}

export const AppHeader: FC<IHeaderProps> = ({ partner, setLocale, locale }): JSX.Element => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const sidebarToggle = (event: React.MouseEvent<HTMLAnchorElement>): void => {
		event.preventDefault();
		document.body.classList.toggle('sidebar-lg-show');
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const sidebarMinimize = (event: React.MouseEvent<HTMLAnchorElement>): void => {
		event.preventDefault();
		document.body.classList.toggle('sidebar-minimized');
	};

	const mobileSidebarToggle = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
		event.preventDefault();
		document.body.classList.toggle('sidebar-show');
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const asideToggle = (event: React.MouseEvent<HTMLAnchorElement>): void => {
		event.preventDefault();
		document.body.classList.toggle('aside-menu-hidden');
	};

	return (
		<header className="app-header navbar">
			<NavbarToggler className="d-lg-none" onClick={mobileSidebarToggle}>
				<span className="navbar-toggler-icon" />
			</NavbarToggler>
			<NavBarBrand
				full={{
					alt: 'Centauro Rent a Car',
					height: 50,
					src: logo,
					width: 160,
				}}
				minimized={{
					alt: 'Centauro Rent a Car',
					height: 30,
					src: sygnet,
					width: 30,
				}}
			/>

			<HeaderNav className="ms-auto" navbar tag="div">
				<div className="me-md-3">
					<HeaderDropdown partner={partner} />
				</div>
				<LanguageSwitch className="d-sm-down-none" locale={locale} setLocale={setLocale} />
			</HeaderNav>
		</header>
	);
};
