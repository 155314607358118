import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import { thunk } from 'redux-thunk';

import { CreationMethod } from '@crac/core/modules/shared/types/CreationMethod';

import { rootReducer } from './reducers';

const logger = createLogger({ collapsed: true, diff: true, duration: true });

/**
 * Root app state type
 */
export type AppStateType = ReturnType<typeof rootReducer>;

/**
 * Config store
 * @param preloadedState Initial state
 * @returns App Store
 */
export const setupStore = (preloadedState?: Partial<AppStateType>) => {
	return configureStore({
		reducer: rootReducer,
		devTools: process.env.NODE_ENV !== 'production',
		preloadedState,
		middleware: (getDefaultMiddleware) => {
			const middleware = getDefaultMiddleware({
				thunk: {
					extraArgument: {
						creationMethod: CreationMethod.PARTNERS,
					},
				},
			}).concat(thunk);

			if (process.env.NODE_ENV === 'development') {
				middleware.concat(logger);
			}

			return middleware;
		},
	});
};

export const store = setupStore();

export type AppStoreType = ReturnType<typeof setupStore>;
export type AppDispatchType = typeof store.dispatch;
