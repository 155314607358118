export const routesIdMap = {
	AutoLoginAgency: 3,
	AutoLoginVendor: 4,
	Bookings: 100,
	BookingsDetail: 140,
	BookingsDetailDocuments: 141,
	BookingsDetailDrivers: 142,
	BookingsDetailExcess: 143,
	BookingsDetailExtend: 144,
	BookingsDetailExtendConfirm: 144.1,
	BookingsDetailFines: 145,
	BookingsDetailFinesDetail: 146,
	BookingsDetailVehicles: 147,
	BookingsNewAvailability: 120,
	BookingsNewConfirmation: 128,
	BookingsNewGroups: 122,
	BookingsNewInvoiceTo: 126,
	BookingsNewServices: 124,
	BookingsSearch: 110,
	Cards: 200,
	Commissions: 130,
	Dashboard: 2,
	Home: 1,
	Login: 0,
};
