import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { reducerStorage } from '@crac/core/helpers/reducerStorage';
import type { IVehicleGroupAvailabilityAndPrice } from '@crac/core/models/entities/Availability';
import { UnitTime } from '@crac/core/models/types/UnitTime';
import { bookingAvailabilityReset, bookingGetAvailability } from '@crac/core/redux/actions/BookingActions';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import type { IAvailabilityReducerState } from '@crac/core/redux-store/reducersState/booking/AvailabilityReducerState';

const initialState: IAvailabilityReducerState = {
	availabilityGroups: [],
	availabilityDuration: 0,
	availabilityUnitTime: UnitTime.Day,
	availabilityPaymentDuration: 0,
	availabilityRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const avilabilityStorageKey = 'BOOKING_AVAILABILITY';

const { getStorage, removeStorage, updateStorage } = reducerStorage<IAvailabilityReducerState>(
	avilabilityStorageKey,
	initialState,
	'sessionStorage',
);

const availabilitySlice = createSlice({
	name: 'AvailabilitySlice',
	initialState: getStorage(),
	reducers: {},
	extraReducers: (builder) => {
		// GET AVAILABILITY
		builder
			.addCase(bookingGetAvailability.pending, (state) => {
				return updateStorage(state, {
					...state,
					availabilityDuration: 0,
					availabilityUnitTime: UnitTime.Day,
					availabilityRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				});
			})
			.addCase(bookingGetAvailability.fulfilled, (state, { payload }) => {
				return updateStorage(state, {
					availabilityDuration: payload?.duration as number,
					availabilityGroups: payload?.vehicleGroupsAvailability as IVehicleGroupAvailabilityAndPrice[],
					availabilityUnitTime: payload?.unitTime as number,
					availabilityPaymentDuration: payload?.paymentDuration as number,
					availabilityRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				});
			})
			.addCase(bookingGetAvailability.rejected, (state, action) => {
				return updateStorage(state, {
					...state,
					availabilityDuration: 0,
					availabilityUnitTime: UnitTime.Day,
					availabilityRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				});
			});
		// CLEAR CACHE & RESET
		builder.addMatcher(isAnyOf(clearGlobalCache, bookingAvailabilityReset), () => {
			removeStorage();
			return getStorage();
		});
	},
});

export const availabilityReducer = availabilitySlice.reducer;
