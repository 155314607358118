import type { IPartner } from '../../models/entities/Partner';
import type {
	IPartnerAutoLogInParams,
	IPartnerGetUnitTimeParams,
	IPartnerLogInParams,
	IPartnerValidateParams,
} from '../../models/serviceParams/PartnerParams';
import type { UnitTime } from '../../models/types/UnitTime';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { PartnerEndPoints } from '../endPoints/PartnerEndPoints';

/**
 * Log in a partner with its username and password.
 *  {string} username Username of the employee.
 *  {string} password Password of the employee.
 * @return {Promise}
 */
export const partnerServiceLogIn = (model: IPartnerLogInParams): Promise<ServiceResponse<IPartner>> => {
	return Api.get<IPartner, IPartnerLogInParams>(PartnerEndPoints.LOGIN, model);
};

/**
 * Partner autologin by code
 */
export const partnerServiceAutoLogIn = (model: IPartnerAutoLogInParams): Promise<ServiceResponse<IPartner>> => {
	return Api.get<IPartner, IPartnerAutoLogInParams>(PartnerEndPoints.AUTO_LOGIN, model);
};

/**
 * Validate partner
 */
export const partnerServiceValidate = (model: IPartnerValidateParams): Promise<ServiceResponse<boolean>> => {
	return Api.get<boolean, IPartnerValidateParams>(PartnerEndPoints.VALIDATE, model);
};

/**
 * Validate partner
 */
export const partnerServiceGetUnitTime = (model: IPartnerGetUnitTimeParams) => {
	return Api.get<UnitTime, IPartnerValidateParams>(PartnerEndPoints.GET_UNIT_TIME_BY_PARTNER, model);
};
