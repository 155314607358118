import { styled } from 'styled-components';

import { Nav, NavItem } from '@crac/components/external/reactstrap/nav';

export const LocaleSwitch = styled(NavItem)`
	background: #ffe396;
	padding: 0 0.5rem;
	color: #001e96;
	cursor: pointer;
	height: 100%;

	.nav-link {
		align-items: center;
		display: flex;
		height: 100%;
	}

	.icon {
		display: inline-block;
		margin: 0 5px;
		font-weight: bold;
	}

	.current {
		font-weight: bold;
	}
`;

export const HeaderNav = styled(Nav)`
	height: 100%;
`;
