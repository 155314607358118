import { createSlice } from '@reduxjs/toolkit';

import { activeLoader, clearGlobalCache, disableLoader } from '@crac/core/redux/actions/CommonActions';
import type { ILoaderReducerState } from '@crac/core/redux-store/reducersState/common';

const initialState: ILoaderReducerState = {
	icon: undefined,
	isVisible: false,
	message: undefined,
	requestCount: 0,
};

const loaderSlice = createSlice({
	name: 'LoaderSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR CACHE
		builder.addCase(clearGlobalCache, () => {
			return initialState;
		});
		// ACTIVE
		builder.addCase(activeLoader, (state, { payload }) => {
			return {
				...state,
				icon: payload?.icon ? payload.icon : initialState.icon,
				isVisible: true,
				message: payload?.message ? payload.message : initialState.message,
				requestCount: state.requestCount + 1,
			};
		});
		// DISABLE
		builder.addCase(disableLoader, (state) => {
			return {
				...state,
				icon: initialState.icon,
				isVisible: state.requestCount - 1 > 0,
				message: initialState.message,
				requestCount: state.requestCount > 0 ? state.requestCount - 1 : 0,
			};
		});
	},
});

export const loaderReducer = loaderSlice.reducer;
