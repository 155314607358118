import { createSlice } from '@reduxjs/toolkit';

import type { IFine } from '@crac/core/models/entities/Fine';
import type { IRequestStateData } from '@crac/core/modules/shared/types/RequestStateType';
import { fineGetByBooking, fineGetById } from '@crac/core/redux/actions/FineActions';
import { linkGetByFineNumber, linkGetFineNotification } from '@crac/core/redux/actions/LinkActions';
import type { IFineReducerState } from '@crac/core/redux-store/reducersState/fine';

const initialState: IFineReducerState = {
	fine: {
		data: null,
		inProgress: false,
		messages: [],
		ok: true,
	},
	fines: {
		data: [],
		inProgress: false,
		messages: [],
		ok: true,
	},
	fineLinks: [],
	fineLinksRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	notificationLinks: [],
	notificationLinksRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const finesSlice = createSlice({
	name: 'FinesSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// GET BY ID
		builder
			.addCase(fineGetById.pending, (state) => {
				return {
					...state,
					fine: {
						data: null,
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fineGetById.fulfilled, (state, action) => {
				return {
					...state,
					fine: {
						data: action.payload,
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fineGetById.rejected, (state, action) => {
				return {
					...state,
					fine: {
						data: (state.fine as IRequestStateData<IFine>).data,
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// GET BY BOOKING
		builder
			.addCase(fineGetByBooking.pending, (state) => {
				return {
					...state,
					fines: {
						data: state.fines ? state.fines.data : [],
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fineGetByBooking.fulfilled, (state, action) => {
				return {
					...state,
					fines: {
						data: action.payload,
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fineGetByBooking.rejected, (state, action) => {
				return {
					...state,
					fines: {
						data: state.fines ? state.fines.data : [],
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// GET BY FINE NUMBER
		builder
			.addCase(linkGetByFineNumber.pending, (state) => {
				return {
					...state,
					fineLinks: [],
					fineLinksRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(linkGetByFineNumber.fulfilled, (state, action) => {
				return {
					...state,
					fineLinks: action.payload,
					fineLinksRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(linkGetByFineNumber.rejected, (state, action) => {
				return {
					...state,
					fineLinks: [],
					fineLinksRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// GET FINE NOTIFICATION
		builder
			.addCase(linkGetFineNotification.pending, (state) => {
				return {
					...state,
					notificationLinks: [],
					notificationLinksRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(linkGetFineNotification.fulfilled, (state, action) => {
				return {
					...state,
					notificationLinks: action.payload,
					notificationLinksRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(linkGetFineNotification.rejected, (state, action) => {
				return {
					...state,
					notificationLinks: [],
					notificationLinksRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const finesReducer = finesSlice.reducer;
