import { combineReducers } from '@reduxjs/toolkit';

import { agencyReducer as Agency } from './Agency';
import { bookingReducers as Booking } from './Booking';
import { branchesReducer as Branch } from './Branch';
import { cardsReducer as Cards } from './Cards';
import { commissionsReducer as Commission } from './Commission';
import { commonReducers as Commons } from './Commons';
import { companyReducer as Company } from './Company';
import { countryReducer as Country } from './Country';
import { customerReducer as Customer } from './Customer';
import { emailsReducer as Emails } from './Emails';
import { finesReducer as Fines } from './Fines';
import { franchiseReducer as Franchise } from './Franchise';
import { languageReducer as Languages } from './language';
import { linkReducer as Link } from './Link';
import { employeeReducer as Partner } from './Partner';
import { providerReducer as Provider } from './Provider';
import { vehicleGroupReducer as VehicleGroup } from './VehicleGroup';
import { vehicleLinesReducer as VehicleLines } from './VehicleLines';
import { vendorReducer as Vendor } from './Vendor';

export const rootReducer = combineReducers({
	Agency,
	Booking,
	Branch,
	Cards,
	Commission,
	Commons,
	Company,
	Country,
	Customer,
	Emails,
	Fines,
	Franchise,
	Languages,
	Link,
	Partner,
	Provider,
	VehicleGroup,
	VehicleLines,
	Vendor,
});
