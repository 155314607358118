import type { FC } from 'react';
import React, { useEffect } from 'react';

import { t } from '@lingui/macro';

import { NavLink } from '@crac/components/external/reactstrap/nav';

import { LocaleSwitch } from './styles';

interface ILanguageSwitchProps {
	locale?: string;
	setLocale?: (locale: string) => void;
	className: string;
}

export const LanguageSwitch: FC<ILanguageSwitchProps> = ({ locale, setLocale, className }) => {
	const localeToChange = locale === 'es' ? 'en' : 'es';

	const handleSetLocale = (event: React.MouseEvent<any, MouseEvent>): void => {
		event.preventDefault();

		if (setLocale) {
			setLocale(localeToChange);
		}
	};

	useEffect(() => {
		if (setLocale) {
			setLocale(locale as string);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<LocaleSwitch className={className}>
			<NavLink className="nav-link" onClick={handleSetLocale} title={t`Switch language`} to="#">
				<span className="current">{locale ? locale.toLocaleUpperCase() : null}</span>
				<i className="icon fa fa-arrow-right" />
				{localeToChange.toLocaleUpperCase()}
			</NavLink>
		</LocaleSwitch>
	);
};
