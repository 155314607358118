import type { FC } from 'react';
import { useEffect, useMemo } from 'react';

import { type Action, type Dispatch, bindActionCreators } from '@reduxjs/toolkit';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Container } from '@crac/components/external/reactstrap/container';
import { FullPageLoader } from '@crac/components/intranet/shared/fullPageLoader/index';
import { AppSidebar } from '@crac/components/intranet/shared/layout/sidebar';
import type { IPartner } from '@crac/core/models/entities/Partner';
import { setLocale } from '@crac/core/redux/actions/CommonActions';

import { navigationItems } from '~/config/menuItems';
import { routes, routesIdMap } from '~/config/routes';
import { useSignalR } from '~/hooks/useSignalR';
import { usePartnerSelector, useProvidersSelector } from '~/hooks/useStateSelectors';
import { useAppDispatch } from '~/redux/hooks';
import { useCommonsLocaleSelector, useLoaderSelector } from '~/redux/selectors/CommonsSelectors';
import { removeBrowserCache } from '~/serviceWorker';

import { Footer } from './Footer';
import { AppHeader } from './Header';

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: Dispatch<Action>) =>
	bindActionCreators(
		{
			setLocale,
		},
		dispatch,
	);

// { loader, locale, partner, providers, setLocale }

export const Layout: FC = (): JSX.Element => {
	const partner = usePartnerSelector();
	const loader = useLoaderSelector();
	const { locale } = useCommonsLocaleSelector();
	const providers = useProvidersSelector();

	const dispatch = useAppDispatch();

	const { setLocale } = mapDispatchToProps(dispatch);

	const location = useLocation();
	const history = useNavigate();
	const hubConnection = useSignalR(partner.token);
	const menuItems = useMemo(() => navigationItems(partner, providers, partner.permissions), [partner, providers]);

	const updateAllBrowsers = () => {
		window.location.reload();
	};

	useEffect(() => {
		const launchScript = (response: any): void => {
			if (response.ok) {
				switch (response.script) {
					case 'F5':
						updateAllBrowsers();
						break;
					case 'CLOSE_SESSION':
						history(routes[routesIdMap.Login].path);
						break;
					case 'DELETE_BROWSER_CACHES':
						removeBrowserCache();
						setTimeout(() => {
							window.location.reload();
							window.location.reload();
						}, 0);
						break;
					default:
						break;
				}
			}
		};

		if (hubConnection) {
			hubConnection.addListener('onLaunchScript', launchScript);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hubConnection]);

	return (
		<div className="app">
			<FullPageLoader icon={loader.icon} isVisible={Boolean(loader.isVisible)} message={loader.message} />
			<AppHeader locale={locale} partner={partner as IPartner} setLocale={(loc) => setLocale(loc)} />
			<section className="app-body">
				<AppSidebar items={menuItems} location={location} permissions={partner.permissions} />
				<main className="main">
					<Container fluid>
						<Outlet />
					</Container>
				</main>
			</section>
			<Footer />
		</div>
	);
};
