/* eslint-disable no-console */
import { useEffect, useState } from 'react';

import type { HubConnection } from '@aspnet/signalr';
import { HttpTransportType, HubConnectionBuilder } from '@aspnet/signalr';

import { REALTIME_ENDPOINT } from '@crac/core/modules/shared/api/ApiEndPoint';

interface ISignalrConnection {
	hubConnection: HubConnection | null;
	addListener: (event: string, callback: (...args: any[]) => void) => void;
	removeListener: (event: string) => void;
	invoke: (method: string, ...args: any[]) => void;
}

export const useSignalR = (token: string): ISignalrConnection => {
	const [hubConnection, setHubConnection] = useState<HubConnection | null>(null);

	useEffect(() => {
		// Set the initial SignalR Hub Connection.
		const createHubConnection = async () => {
			// Build new Hub Connection, url is currently hard coded.
			const hubConnect = new HubConnectionBuilder()
				.withUrl(REALTIME_ENDPOINT, {
					accessTokenFactory: () => token,
					transport: HttpTransportType.WebSockets,
				})
				.build();

			try {
				await hubConnect.start();
				console.info('Connection successful!');
			} catch (err) {
				console.error(err);
			}
			setHubConnection(hubConnect);
		};

		if (!hubConnection && token) {
			createHubConnection();
		}
	}, [hubConnection, token]);

	const addListener = (event: string, callback: any): void => {
		if (hubConnection) {
			hubConnection.on(event, callback);
		}
	};

	const removeListener = (event: string): void => {
		if (hubConnection) {
			hubConnection.off(event);
		}
	};

	const invoke = (method: string, ...args: any[]): void => {
		if (hubConnection) {
			hubConnection.invoke(method, ...args);
		}
	};

	return {
		addListener,
		hubConnection,
		invoke,
		removeListener,
	};
};
