import React from 'react';

import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from './routesIdMap';

const Dashboard = React.lazy(() => import('~/pages/Dashboard/index'));

export const commonRoutes: { [key: number]: IRouteConfig } = {};

commonRoutes[routesIdMap.Dashboard] = {
	component: Dashboard,
	exact: true,
	id: routesIdMap.Dashboard,
	name: 'Home',
	path: '/dashboard',
	permissions: [PermissionsType.Access],
};
