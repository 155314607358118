import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import { linkClear, linkGetByFranchiseLine } from '@crac/core/redux/actions/LinkActions';
import type { ILinkReducerState } from '@crac/core/redux-store/reducersState/link';

const initialState: Omit<ILinkReducerState, 'documentLinks'> = {
	links: [],
	linksRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const linkSlice = createSlice({
	name: 'LinkSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(linkGetByFranchiseLine.pending, (state) => {
				return {
					...state,
					linksRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(linkGetByFranchiseLine.fulfilled, (state, action) => {
				return {
					...state,
					links: deepCopy(action.payload),
					linksRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(linkGetByFranchiseLine.rejected, (state, action) => {
				return {
					...state,
					linksRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// CLEAR CACHE & RESET
		builder.addMatcher(isAnyOf(clearGlobalCache, linkClear), () => {
			return { ...initialState };
		});
	},
});

export const linkReducer = linkSlice.reducer;
