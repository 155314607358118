import { createSlice } from '@reduxjs/toolkit';

import { reducerStorage } from '@crac/core/helpers/reducerStorage';
import type { ICountry } from '@crac/core/models/entities/Country';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import { countryGetAll } from '@crac/core/redux/actions/CountryActions';
import type { ICountryReducerState } from '@crac/core/redux-store/reducersState/country';

const { removeStorage, updateStorage, getStorage } = reducerStorage<ICountry[] | []>('countries', [], 'localStorage');

const initialState: ICountryReducerState = {
	countries: getStorage(),
	countriesGetAllRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};
const countrySlice = createSlice({
	name: 'CountrySlice',
	initialState: {
		...initialState,
	},
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR CACHE
		builder.addCase(clearGlobalCache, () => {
			removeStorage();
			return initialState;
		});
		// GET ALL
		builder
			.addCase(countryGetAll.pending, (state) => {
				return {
					...state,
					countries: [],
					countriesGetAllRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(countryGetAll.fulfilled, (state, action) => {
				return {
					...state,
					countries: updateStorage(state.countries, action.payload),
					countriesGetAllRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(countryGetAll.rejected, (state, action) => {
				return {
					...state,
					countriesGetAllRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const countryReducer = countrySlice.reducer;
