import { createSlice } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { managerLocalStorage } from '@crac/core/helpers/reducerStorage';
import {
	branchGetAll,
	branchGetByPartnerCodeAndType,
	branchGetNearbyBranchesWithAvailability,
	branchGetNearbyBranchesWithAvailabilityReset,
} from '@crac/core/redux/actions/BranchActions';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import type { IBranchReducerState } from '@crac/core/redux-store/reducersState/branch';

const BRANCH_BY_PARNER_KEY = 'branchesByPartner';
const BRANCH_GET_ALL_KEY = 'branches';
const storageManager = managerLocalStorage('localStorage');

const initialState: IBranchReducerState = {
	branchByPartnerRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	branchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	branches: storageManager.getItem(BRANCH_GET_ALL_KEY) || [],
	branchesByPartner: storageManager.getItem(BRANCH_BY_PARNER_KEY) || [],
	nearbyBranches: [],
	nearbyBranchesAvailabilityRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const branchesSlice = createSlice({
	name: 'BranchesSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// GET ALL
		builder
			.addCase(branchGetAll.pending, (state) => {
				return {
					...state,
					branchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(branchGetAll.fulfilled, (state, action) => {
				storageManager.setItem(BRANCH_GET_ALL_KEY, action.payload);
				return {
					...state,
					branchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					branches: deepCopy(action.payload),
				};
			})
			.addCase(branchGetAll.rejected, (state, action) => {
				return {
					...state,
					branchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// GET BY PARTNER CODE AND TYPE
		builder
			.addCase(branchGetByPartnerCodeAndType.pending, (state) => {
				return {
					...state,
					branchByPartnerRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(branchGetByPartnerCodeAndType.fulfilled, (state, action) => {
				storageManager.setItem(BRANCH_BY_PARNER_KEY, action.payload);
				return {
					...state,
					branchByPartnerRequest: {
						inProgress: false,
						messages: [],
						ok: false,
					},
					branchesByPartner: deepCopy(action.payload),
				};
			})
			.addCase(branchGetByPartnerCodeAndType.rejected, (state, action) => {
				return {
					...state,
					branchByPartnerRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// GET NEARBY BRANCHES WITH AVAILABILITY
		builder
			.addCase(branchGetNearbyBranchesWithAvailability.pending, (state) => {
				return {
					...state,
					nearbyBranchesAvailabilityRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(branchGetNearbyBranchesWithAvailability.fulfilled, (state, action) => {
				return {
					...state,
					nearbyBranchesAvailabilityRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					nearbyBranches: action.payload,
				};
			})
			.addCase(branchGetNearbyBranchesWithAvailability.rejected, (state, action) => {
				return {
					...state,
					nearbyBranchesAvailabilityRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// GET NEARBY BRANCHES WITH AVAILABILITY RESET
		builder.addCase(branchGetNearbyBranchesWithAvailabilityReset, (state) => {
			return {
				...state,
				nearbyBranches: [],
				nearbyBranchesAvailabilityRequest: initialState.nearbyBranchesAvailabilityRequest,
			};
		});
		// CLEAR CACHE & RESET
		builder.addCase(clearGlobalCache, () => {
			storageManager.removeItem(BRANCH_GET_ALL_KEY);
			storageManager.removeItem(BRANCH_BY_PARNER_KEY);
			return initialState;
		});
	},
});

export const branchesReducer = branchesSlice.reducer;
