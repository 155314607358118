import type { IBranch } from '@crac/core/models/entities/Branch';
import type { IProvider } from '@crac/core/models/entities/Provider';

import { useAppSelector } from '~/redux/hooks';

export const useGetProviderByBranchSelector = (brancCode: string): IProvider | null =>
	useAppSelector((state) => {
		const currentBranch = state.Branch.branches.find((branch) => branch.code === brancCode) as IBranch;
		const currentProvider = state.Provider.providers.find((provider) => provider.name === currentBranch.provider);
		return currentProvider ? currentProvider : null;
	});

export const useProviderSelector = () => useAppSelector((state) => state.Provider);
