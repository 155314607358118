import { createSlice } from '@reduxjs/toolkit';

import { reducerStorage } from '@crac/core/helpers/reducerStorage';
import type { IVendor } from '@crac/core/models/entities/Vendor';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import { vendorGetByCode } from '@crac/core/redux/actions/VendorActions';
import type { IVendorReducerState } from '@crac/core/redux-store/reducersState/vendor';

const initialState: IVendorReducerState = {
	vendor: null,
	vendorRequest: { inProgress: false, messages: [], ok: true },
};

const { removeStorage, updateStorage, getStorage } = reducerStorage<IVendor | null>('vendor', null, 'localStorage');

const vendorSlice = createSlice({
	name: 'VendorSlice',
	initialState: {
		...initialState,
		vendor: getStorage(),
	},
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR CACHE
		builder.addCase(clearGlobalCache, () => {
			removeStorage();
			return initialState;
		});
		// GET BY CODE
		builder
			.addCase(vendorGetByCode.pending, (state) => {
				return {
					...state,
					vendor: null,
					vendorRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vendorGetByCode.fulfilled, (state, action) => {
				return {
					...state,
					vendor: updateStorage(null, action.payload),
					vendorRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vendorGetByCode.rejected, (state, action) => {
				return {
					...state,
					vendor: updateStorage(null, state.vendor),
					vendorRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const vendorReducer = vendorSlice.reducer;
