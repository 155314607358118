import { createSlice } from '@reduxjs/toolkit';

import {
	bookingExtend,
	bookingExtendAvailabilityReset,
	bookingExtendCallBack,
	bookingGetExtendAvailability,
} from '@crac/core/redux/actions/BookingModifyActions';
import type { IExtendReducerState } from '@crac/core/redux-store/reducersState/booking/ExtendReducerState';

const initialState: IExtendReducerState = {
	booking: null,
	bookingLines: [],
	extendAvailabilityRequest: { inProgress: false, messages: [], ok: true },
	extendCallBackRequest: { inProgress: false, messages: [], ok: true },
	extendConfirmRequest: { inProgress: false, messages: [], ok: true },
	extendSendPaymentEmail: { data: false, inProgress: false, messages: [], ok: true },
	transaction: null,
};

const extendSlice = createSlice({
	name: 'ExtendSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// RESET
		builder.addCase(bookingExtendAvailabilityReset, () => {
			return {
				...initialState,
			};
		});
		// GET EXTEND AVAILABILITY
		builder
			.addCase(bookingGetExtendAvailability.pending, () => {
				return {
					...initialState,
				};
			})
			.addCase(bookingGetExtendAvailability.fulfilled, (state, action) => {
				return {
					...state,
					bookingLines: action.payload,
					extendAvailabilityRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingGetExtendAvailability.rejected, (state, action) => {
				return {
					...state,
					extendAvailabilityRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// EXTEND
		builder
			.addCase(bookingExtend.pending, (state) => {
				return {
					...state,
					booking: null,
					extendConfirmRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
					transaction: null,
				};
			})
			.addCase(bookingExtend.fulfilled, (state, action) => {
				return {
					...state,
					booking: action.payload?.booking,
					extendConfirmRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					transaction: action.payload?.transaction,
				};
			})
			.addCase(bookingExtend.rejected, (state, action) => {
				return {
					...state,
					extendConfirmRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// EXTEND CALLBACK
		builder
			.addCase(bookingExtendCallBack.pending, (state) => {
				return {
					...state,
					booking: null,
					extendCallBackRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
					transaction: null,
				};
			})
			.addCase(bookingExtendCallBack.fulfilled, (state, action) => {
				return {
					...state,
					booking: action.payload.booking,
					extendCallBackRequest: {
						inProgress: false,
						messages: [],
						ok: false,
					},
					transaction: action.payload.transaction,
				};
			})
			.addCase(bookingExtendCallBack.rejected, (state, action) => {
				return {
					...state,
					booking: null,
					extendCallBackRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
					transaction: null,
				};
			});
	},
});

export const extendReducer = extendSlice.reducer;
