import { partnerServiceAutoLogIn, partnerServiceLogIn } from '../../data/services/PartnerService';
import type { IPartner } from '../../models/entities/Partner';
import type { IPartnerAutoLogInParams, IPartnerLogInParams } from '../../models/serviceParams/PartnerParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Logs in a partner using the provided credentials.
 *
 * @param {IPartner} payload - The partner data returned upon successful login.
 * @param {IPartnerLogInParams} params - Parameters including credentials for partner login.
 * @returns {Promise<void>} - A promise that resolves to the partner data after successful login.
 */
export const partnerLogIn = createAsyncAction<IPartner, IPartnerLogInParams>('partner/login', partnerServiceLogIn);

/**
 * Automatically logs in a partner based on stored credentials or session.
 *
 * @param {IPartner} payload - The partner data returned upon successful auto-login.
 * @param {IPartnerAutoLogInParams} params - Parameters for automatic login, typically using a token or session data.
 * @returns {Promise<void>} - A promise that resolves to the partner data after successful auto-login.
 */
export const partnerAutoLogIn = createAsyncAction<IPartner, IPartnerAutoLogInParams>(
	'partner/autoLogin',
	partnerServiceAutoLogIn,
);
