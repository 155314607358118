import { createSlice } from '@reduxjs/toolkit';

import type { ICard } from '@crac/core/models/entities/Card';
import {
	cardGetByCustomer,
	cardLink,
	cardLinkCallBack,
	cardSetPreferred,
	cardUnlink,
} from '@crac/core/redux/actions/CardActions';
import type { ICardReducerState } from '@crac/core/redux-store/reducersState/card';

const initialState: ICardReducerState = {
	cardDeleteRequest: {
		inProgress: false,
		messages: [],
		ok: false,
	},

	cardGetByCustomerRequest: {
		inProgress: false,
		messages: [],
		ok: false,
	},
	cardLinkCallbackRequest: {
		inProgress: false,
		messages: [],
		ok: false,
	},
	cardLinkRequest: {
		inProgress: false,
		messages: [],
		ok: false,
	},
	cardSetPreferredRequest: {
		inProgress: false,
		messages: [],
		ok: false,
	},
	cards: [],
	transaction: null,
};

const cardsSlice = createSlice({
	name: 'CardsSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// GET BY CUSTOMER
		builder
			.addCase(cardGetByCustomer.pending, (state) => {
				return {
					...state,
					cardGetByCustomerRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(cardGetByCustomer.fulfilled, (state, { payload }) => {
				return {
					...state,
					cardGetByCustomerRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					cards: payload,
				};
			})
			.addCase(cardGetByCustomer.rejected, (state, { payload }) => {
				return {
					...state,
					cardGetByCustomerRequest: {
						inProgress: false,
						messages: payload || [],
						ok: false,
					},
				};
			});
		// SET PREFERRED
		builder
			.addCase(cardSetPreferred.pending, (state) => {
				return {
					...state,
					cardSetPreferredRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(cardSetPreferred.fulfilled, (state) => {
				return {
					...state,
					cardSetPreferredRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(cardSetPreferred.rejected, (state, { payload }) => {
				return {
					...state,
					cardSetPreferredRequest: {
						inProgress: false,
						messages: payload || [],
						ok: false,
					},
				};
			});
		// LINK
		builder
			.addCase(cardLink.pending, (state) => {
				return {
					...state,
					cardLinkRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(cardLink.fulfilled, (state, { payload }) => {
				return {
					...state,
					card: payload.data,
					cardLinkRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					transaction: payload.transaction,
				};
			})
			.addCase(cardLink.rejected, (state, { payload }) => {
				return {
					...state,
					cardLinkRequest: {
						inProgress: false,
						messages: payload || [],
						ok: false,
					},
					transaction: null,
				};
			});
		// LINK CALLBACK
		builder
			.addCase(cardLinkCallBack.pending, (state) => {
				return {
					...state,
					cardLinkCallbackRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(cardLinkCallBack.fulfilled, (state, { payload }) => {
				return {
					...state,
					card: payload.data,
					cardLinkCallbackRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					cards: payload.data ? [...state.cards, payload.data as ICard] : state.cards,
					transaction: payload.transaction,
				};
			})
			.addCase(cardLinkCallBack.rejected, (state, { payload }) => {
				return {
					...state,
					cardLinkCallbackRequest: {
						inProgress: false,
						messages: payload || [],
						ok: false,
					},
					transaction: null,
				};
			});
		// UNLINK
		builder
			.addCase(cardUnlink.pending, (state) => {
				return {
					...state,
					cardDeleteRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(cardUnlink.fulfilled, (state) => {
				return {
					...state,
					cardDeleteRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(cardUnlink.rejected, (state, { payload }) => {
				return {
					...state,
					cardDeleteRequest: {
						inProgress: false,
						messages: payload || [],
						ok: false,
					},
				};
			});
	},
});

export const cardsReducer = cardsSlice.reducer;
