import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import type { IPartner } from '@crac/core/models/entities/Partner';
import { clearGlobalCache, userUnauthorized } from '@crac/core/redux/actions/CommonActions';
import { partnerAutoLogIn, partnerLogIn } from '@crac/core/redux/actions/PartnerActions';
import type { IEmployeeReducerState } from '@crac/core/redux-store/reducersState/employee';

const getPartner = (): IPartner | null => {
	if (localStorage.getItem('partner')) {
		return JSON.parse(localStorage.getItem('partner') as string) as IPartner;
	}

	return null;
};

const getToken = (): string | undefined => {
	if (localStorage.getItem('token')) {
		return JSON.parse(localStorage.getItem('token') as string) as string;
	}

	return undefined;
};

const initialState: IEmployeeReducerState = {
	changePassword: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	employee: getPartner(),
	login: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	token: getToken(),
};

const setUserLogin = (data: IPartner): IPartner => {
	localStorage.setItem('partner', JSON.stringify(data));
	localStorage.setItem('token', JSON.stringify(data.token));
	return data;
};

const resetState = (): typeof initialState => {
	localStorage.clear();
	return initialState;
};

const employeeSlice = createSlice({
	name: 'EmployeeSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(partnerLogIn.pending, (state) => {
				return {
					...state,
					login: {
						...state.login,
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(partnerLogIn.fulfilled, (state, action) => {
				return {
					...state,
					employee: setUserLogin(action.payload),
					login: {
						...state.login,
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(partnerLogIn.rejected, (state, action) => {
				return {
					...state,
					login: {
						...state.login,
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});

		builder
			.addCase(partnerAutoLogIn.pending, (state) => {
				return {
					...state,
					login: {
						...state.login,
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(partnerAutoLogIn.fulfilled, (state, action) => {
				return {
					...state,
					employee: setUserLogin(action.payload),
					login: {
						...state.login,
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(partnerAutoLogIn.rejected, (state, action) => {
				return {
					...state,
					login: {
						...state.login,
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CLEAR CACHE & RESET
		builder.addMatcher(isAnyOf(clearGlobalCache, userUnauthorized), () => {
			return resetState();
		});
	},
});

export const employeeReducer = employeeSlice.reducer;
