import { createSlice } from '@reduxjs/toolkit';

import type { IBooking } from '@crac/core/models/entities/Booking';
import { BookingStateType } from '@crac/core/models/types/BookingStateType';
import { bookingGetByBookingNumber } from '@crac/core/redux/actions/BookingActions';
import { bookingCancel, bookingModify } from '@crac/core/redux/actions/BookingModifyActions';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import type { IBookingDetailReducerState } from '@crac/core/redux-store/reducersState/booking/DetailReducerState';

const initialState: IBookingDetailReducerState = {
	booking: null,
	bookingAllowPendingRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	bookingCancelRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	bookingDetailRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	bookingModifyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const bookingDetailSlice = createSlice({
	name: 'BookingDetailSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR CACHE
		builder.addCase(clearGlobalCache, () => {
			return initialState;
		});
		// GET BY BOOKING NUMBER
		builder
			.addCase(bookingGetByBookingNumber.pending, (state) => {
				return {
					...state,
					booking: null,
					bookingDetailRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingGetByBookingNumber.fulfilled, (state, action) => {
				return {
					...state,
					booking: action.payload,
					bookingDetailRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingGetByBookingNumber.rejected, (state, action) => {
				return {
					...state,
					booking: null,
					bookingDetailRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// BOOKING CANCEL
		builder
			.addCase(bookingCancel.pending, (state) => {
				return {
					...state,
					bookingCancelRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingCancel.fulfilled, (state) => {
				return {
					...state,
					booking: {
						...(state.booking as IBooking),
						bookingState: BookingStateType.Cancelled,
					},
					bookingCancelRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingCancel.rejected, (state, action) => {
				return {
					...state,
					bookingCancelRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// BOOKING MODIFY
		builder
			.addCase(bookingModify.pending, (state) => {
				return {
					...state,
					bookingModifyRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingModify.fulfilled, (state, action) => {
				return {
					...state,
					booking: action.payload,
					bookingModifyRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingModify.rejected, (state, action) => {
				return {
					...state,
					bookingModifyRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const bookingDetailReducer = bookingDetailSlice.reducer;
