import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { setBookingLinesInvoiceTo } from '@crac/core/business/booking/BookingInvoiceTo';
import { updateBookingLine } from '@crac/core/business/booking/BookingNewManageState';
import { reducerStorage } from '@crac/core/helpers/reducerStorage';
import { BookingDeliveryType } from '@crac/core/models/types/BookingDeliveryType';
import { BookingType } from '@crac/core/models/types/BookingType';
import { InvoiceToCustomerType } from '@crac/core/models/types/InvoiceToCustomerType';
import { CreationMethod } from '@crac/core/modules/shared/types/CreationMethod';
import {
	bookingNewAddService,
	bookingNewClearSession,
	bookingNewInitAvailabilityStep,
	bookingNewInitGroupsStep,
	bookingNewRemoveService,
	bookingNewSetAgency,
	bookingNewSetAvailabilityData,
	bookingNewSetCompany,
	bookingNewSetCurrentProvider,
	bookingNewSetDropOffBranch,
	bookingNewSetPickUpBranch,
	bookingNewSetVendor,
	bookingNewUpdateBookingLine,
	partnersBookingNewSetGroup,
} from '@crac/core/redux/actions/BookingNewActions';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import type {
	IAvailabilityFormValue,
	IBookingNewReducerState,
} from '@crac/core/redux-store/reducersState/booking/BookingNewReducerState';

const initialState: IBookingNewReducerState = {
	agency: null,
	availabilityFormValue: null,
	booking: {
		bookingLines: [],
	},
	bookingType: BookingType.PREBOOKING,
	company: null,
	creationMethod: CreationMethod.PARTNERS,
	currentProvider: null,
	customer: null,
	customerAgeRange: '',
	group: null,
	invoiceToCustomerType: InvoiceToCustomerType.GENERIC,
	package: null,
	services: [],
	vendor: null,
};

const { getStorage, removeStorage, updateStorage } = reducerStorage<IBookingNewReducerState>(
	'NEW_BOOKING_DATA',
	initialState,
	'sessionStorage',
);

const bookingNewSlice = createSlice({
	name: 'BookingNewSlice',
	initialState: getStorage(),
	reducers: {},
	extraReducers: (builder) => {
		// STEP RESETS
		builder.addCase(bookingNewInitAvailabilityStep, (state) => {
			return updateStorage(state, {
				...initialState,
				availabilityFormValue: null,
			});
		});
		builder.addCase(bookingNewInitGroupsStep, (state) => {
			return updateStorage(state, {
				...state,
				booking: {
					...state.booking,
					bookingLines: [],
				},
				group: null,
				services: [],
			});
		});
		// SET AVAILABILITY DATA
		builder.addCase(bookingNewSetAvailabilityData, (state, { payload }) => {
			return updateStorage(state, {
				availabilityFormValue: payload.availabilityFormValue,
				booking: {
					...state.booking,
					customerBirthDate: payload.availabilityFormValue.customerBirthDate,
					dropOffBranchCode: payload.availabilityFormValue.dropOffBranchCode,
					dropOffDateTime: payload.availabilityFormValue.dropOffDateTime,
					pickUpBranchCode: payload.availabilityFormValue.pickUpBranchCode,
					pickUpDateTime: payload.availabilityFormValue.pickUpDateTime,
					quoteDateTime: payload.availabilityFormValue.quoteDateTime,
				},
			});
		});
		// SET CURRENT PROVIDER
		builder.addCase(bookingNewSetCurrentProvider, (state, { payload }) => {
			return updateStorage(state, {
				booking: state.booking,
				currentProvider: payload,
			});
		});
		/*
		 * SET BRANCH
		 * TODO: Only setting fulfilled
		 */
		builder.addCase(bookingNewSetPickUpBranch.fulfilled, (state, { payload }) => {
			return updateStorage(state, {
				...state,
				availabilityFormValue:
					payload.deliveryType === BookingDeliveryType.address
						? {
								...(state.availabilityFormValue as IAvailabilityFormValue),
								pickUpBranchCodeDelivery: payload.branch ? payload.branch.code : undefined,
							}
						: {
								...(state.availabilityFormValue as IAvailabilityFormValue),
								pickUpBranchCode: payload.branch ? payload.branch.code : undefined,
							},
			});
		});
		builder.addCase(bookingNewSetDropOffBranch, (state, { payload }) => {
			return updateStorage(state, {
				...state,
				availabilityFormValue:
					payload.deliveryType === BookingDeliveryType.address
						? {
								...(state.availabilityFormValue as IAvailabilityFormValue),
								dropOffBranchCodeDelivery: payload.branch ? payload.branch.code : undefined,
							}
						: {
								...(state.availabilityFormValue as IAvailabilityFormValue),
								dropOffBranchCode: payload.branch ? payload.branch.code : undefined,
							},
			});
		});
		// SET GROUP
		builder.addCase(partnersBookingNewSetGroup, (state, { payload }) => {
			return updateStorage(state, payload);
		});
		// SET BOOKING LINES
		builder.addCase(bookingNewAddService, (state, { payload }) => {
			return updateStorage(state, {
				...state,
				booking: {
					...state.booking,
					bookingLines: setBookingLinesInvoiceTo(state, payload.bookingLines, state.package),
				},
				services: payload.services,
			});
		});
		builder.addCase(bookingNewRemoveService, (state, { payload }) => {
			return updateStorage(state, {
				...state,
				booking: {
					...state.booking,
					bookingLines: payload.bookingLines,
				},
				services: payload.services,
			});
		});
		builder.addCase(bookingNewUpdateBookingLine, (state, { payload }) => {
			return updateStorage(state, {
				...state,
				booking: updateBookingLine(state, payload),
			});
		});
		// SET AGENCY, VENDOR OR COMPANY
		builder.addCase(bookingNewSetAgency, (state, { payload }) => {
			return updateStorage(state, {
				...state,
				agency: payload,
				bookingType: payload ? payload.contractType : undefined,
			});
		});
		builder.addCase(bookingNewSetVendor, (state, { payload }) => {
			return updateStorage(state, {
				...state,
				bookingType: payload ? payload.contractType : undefined,
				vendor: payload,
			});
		});
		builder.addCase(bookingNewSetCompany, (state, { payload }) => {
			return updateStorage(state, {
				...state,
				bookingType: payload ? payload.contractType : undefined,
				company: payload,
				agency: payload
					? {
							code: payload.code,
							contractType: payload?.contractType,
							name: payload?.name,
							amountToInvoice: payload.amountToInvoice,
						}
					: null,
			});
		});
		// CLEAR CACHE & RESET
		builder.addMatcher(isAnyOf(clearGlobalCache, bookingNewClearSession), () => {
			removeStorage();
			return initialState;
		});
	},
});

export const bookingNewReducer = bookingNewSlice.reducer;
