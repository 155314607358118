import { createSlice } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { vehicleGroupGetByBranch } from '@crac/core/redux/actions/VehicleGroupActions';
import type { IVehicleGroupReducerState } from '@crac/core/redux-store/reducersState/vehicleGroup';

const initialState: IVehicleGroupReducerState = {
	vehicleGroup: null,
	vehicleGroupsByBranch: [],
	vehicleGroupsByBranchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	vehicleGroupsByProvider: [],
	vehicleGroupsByProviderRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const vehicleGroupSlice = createSlice({
	name: 'VehicleGroupSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(vehicleGroupGetByBranch.pending, (state) => {
				return {
					...state,
					vehicleGroupsByBranchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleGroupGetByBranch.fulfilled, (state, action) => {
				return {
					...state,
					vehicleGroupsByBranch: deepCopy(action.payload),
					vehicleGroupsByBranchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleGroupGetByBranch.rejected, (state, action) => {
				return {
					...state,
					vehicleGroupsByBranchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const vehicleGroupReducer = vehicleGroupSlice.reducer;
