/* eslint-disable max-lines-per-function */
import type { FC } from 'react';
import React from 'react';
const privacyPolicyEN = (
	<>
		<h3>1.- Ownership</h3>
		<p>
			The website https://partners.centauro.net/ (hereinafter, &quot;Partner Area&quot;) is the exclusive property
			of the Spanish company CENTAURO RENT A CAR, S.L.U. (hereinafter, &quot;Centauro Rent a Car&quot;), with
			registered office at Avenida País Valencia, 9, Bloque A, Local 4, CP 03509 Finestrat, Alicante, Spain;
			holder of Tax ID Code B03965506; email legalinquiries@centauro.net; and entered in the Trade Register of
			Alicante, Volume 1,745, Folio 215, Sheet A-29.219, Entry 1§.
		</p>
		<h3>2.- Purpose</h3>
		<p>
			The purpose of these Conditions of Use is to regulate the rights, obligations and responsibilities arising
			from access, browsing and use of the Partner Area by commercial partners and collaborators of Centauro Rent
			a Car (hereinafter the &quot;Partner&quot;).The mere access, browsing or use of this site implies the
			knowledge and full acceptance of these Conditions of Use and of those which Centauro Rent a Car may
			establish at any time. Consequently, it will be the Partner&apos;s responsibility to read the conditions in
			force carefully every time he uses this website.If specific agreements are in force between the Partner and
			Centauro Rent a Car, these Conditions of Use will be applied in a subsidiary and complementary way to what
			is established in such agreements.
		</p>
		<h3>3.- Use</h3>
		<p>
			The Partner will use the Partner Area to make, consult and manage car rental reservations with Centauro Rent
			a Car, as well as to consult and manage the commissions, invoices and documentation derived from them.The
			Partner must use the Partner Area, its services and content diligently and will assume any liability that
			may arise from misuse, including possible unauthorized disclosure of data or information, as well as from
			the breach of the Terms of Use or any contractual or legal obligation in force.The Partner may only use the
			Partner Area for his own direct use and will refrain from carrying out any act which, directly or
			indirectly, implies the use or exploitation of the Partner Area by third parties without express prior
			authorization from Centauro Rent a Car.In particular, the Partner will be responsible for any action taken
			in the Partner Area with his data or means of access, as well as for the completeness and accuracy of the
			data and information that he incorporates into the Partner Area. This includes, specifically, car rental
			reservations that are requested.
		</p>
		<h3>4.- Security</h3>
		<p>
			Centauro Rent a Car adopts appropriate security measures to detect the existence of computer viruses.
			However, due to the fact that the security measures of the computer systems on the Internet are not entirely
			reliable, Centauro Rent a Car cannot guarantee the absence of viruses or other elements harmful to the
			Partner&apos;s computer systems.The Partner will use the contents and services provided by Centauro Rent a
			Car in accordance with current legislation and will assume the corresponding responsibilities for any
			conduct or activities which, in any way, may be unlawful or harmful to the rights of third parties or which
			may jeopardize or block the use of this website by Centauro Rent a Car or other users.Likewise, the Partner
			may not use the Partner Area to transmit, store, disseminate, promote or distribute data or content that
			carry viruses or any other computer code, files or programs designed to interrupt, destroy or impair the
			operation of any program or computer equipment or telecommunications.
		</p>
		<h3>5.- Liability</h3>
		<p>
			Centauro Rent a Car does not accept liability for any service interruptions, delays, errors or malfunctions
			in the website, nor, in general, for other inconveniences that have their origin in causes beyond the
			control of this company, and / or due to a fraudulent or culpable action of the Partner and / or due to
			force majeure. The concept of force majeure shall be understood to include all events occurring outside the
			control of Centauro Rent a Car, such as but not limited to: technical incidents, failure of third parties,
			operators or service companies, acts of government, lack of access to third party networks, acts or
			omissions by public authorities, events that result from natural phenomena, blackouts, etc., and attacks on
			the security or integrity of the computer system.Centauro Rent a Car will not assume any responsibility for
			direct or indirect damage, emerging damage and/or loss of profit, and it will have the right to temporarily
			suspend the services and contents of the Partner Area in order to perform maintenance operations,
			improvement or repair work with no need to pay compensation to the Partner on this account.Except in cases
			where the law expressly imposes the contrary, and exclusively to the extent dictated by law, Centauro Rent a
			Car does not guarantee or accept any liability for the data, content, information and services that are
			incorporated and offered from the Partner Area, nor for any possible damages caused by the use of
			information, data and services of the Partner Area.
		</p>
		<h3>6.- Industrial and intellectual property</h3>
		<p>
			The Partner Area and its contents are the exclusive property of Centauro Rent a Car. The access, browsing
			and use of the Partner Area by the commercial partners and collaborators of Centauro Rent a Car does not
			imply the granting of any right regarding this area to the Partner.The distinctive signs (graphics and word
			signs) that appear in this website are the exclusive property of their respective legitimate owners.
			Consequently, its use by third parties without proper authorization is prohibited.The contents, texts,
			photographs, designs, logos, images, sounds, videos, animations, recordings, computer programs, source codes
			and, in general, any existing intellectual creation on this website are protected under copyright by current
			legislation on intellectual property.The reproduction, storage (except that necessary for the loading of
			this website in the browser or temporary storage), transformation, distribution, public communication,
			making available to the public and in general any other form of exploitation, by any means or procedure, of
			all or part of the contents protected by exclusive rights of Centauro Rent a Car is prohibited, except in
			the case of prior and express authorization to this effect.Centauro Rent a Car reserves all rights to the
			contents, information, data and services. Centauro Rent a Car does not grant any license or authorization
			for use to the Partner regarding its contents, data or services other than that expressly detailed in these
			Conditions of Use.
		</p>
		<h3>7.- Data protection</h3>
		<p>
			Any processing of personal data carried out as a consequence of access, browsing and use of the Partner Area
			will be regulated by the specific agreements established between Centauro Rent a Car and the Partner, and
			the Privacy Policy of the website:{' '}
			<a href="https://www.centauro.net/en/privacy-policy/" rel="noreferrer" target="_blank">
				https://www.centauro.net/en/privacy-policy/
			</a>{' '}
			will apply in a subsidiary and complementary way.
		</p>
		<h3>8.- Confidentiality</h3>
		<p>
			The Partner undertakes to maintain confidentiality and not to disseminate further the information contained
			or obtained by access, browsing and use of the Partner Area, except: (i) in compliance with a legal
			obligation or an administrative or judicial order; or (ii) in order to enforce or permit the compliance with
			rights or obligations, or (iii) in order to report on a restricted basis to its advisers or auditors,
			provided that these individuals commit to keep this information confidential in accordance with their
			professional standards.
		</p>
		<h3>9.- General Terms and Conditions</h3>
		<p>
			Without prejudice to what is established in these Conditions of Use, all the Policies, Commercial Conditions
			and General Conditions of Centauro Rent a Car available on the website{' '}
			<a href="https://www.centauro.net/" rel="noreferrer" target="_blank">
				https://www.centauro.net/
			</a>
			will be applied in a subsidiary and complementary way.
		</p>
		<h3>10.- Applicable law and competent jurisdiction</h3>
		<p>
			These Terms of Use are governed by and interpreted in accordance with the laws of Spain. The Courts and
			Tribunals of Benidorm will be competent for any claim.
		</p>
	</>
);

const privacyPolicyES = (
	<>
		<h3>1.- Titularidad</h3>
		<p>
			El sitio web partners.centauro.net (en adelante, “Partner Area”) es titularidad exclusiva de la sociedad
			mercantil española CENTAURO RENT A CAR, S.L.U. (en adelante, “Centauro Rent a Car”), con domicilio sitio en
			Avenida País Valencia, 9, Bloque A, Local 4, CP 03509 Finestrat, Alicante, España; provista de CIF
			B03965506; email legalinquiries@centauro.net; e inscrita en el Registro Mercantil de Alicante, al Tomo
			1.745, Folio 215, Hoja A-29.219, Inscripción 1ª.
		</p>
		<h3>2.- Objeto</h3>
		<p>
			Las presentes Condiciones de Uso tienen por objeto regular los derechos, obligaciones y responsabilidades
			derivadas del acceso, navegación y uso del Partner Area por los socios comerciales y colaboradores de
			Centauro Rent a Car (en adelante, el “Partner”).El mero acceso, navegación o uso de este sitio implica el
			conocimiento y plena aceptación de las presentes Condiciones de Uso y de las que, en cualquier momento,
			pueda establecer Centauro Rent a Car. En consecuencia, será responsabilidad del Partner la atenta lectura de
			las condiciones vigentes en cada uso de este sitio web.En caso de existencia de acuerdos específicos entre
			el Partner y Centauro Rent a Car, las presentes Condiciones de Uso se aplicarán de forma subsidiaria y
			complementaria a lo que se establezca en dichos acuerdos.
		</p>
		<h3>3.- Uso</h3>
		<p>
			El Partner usará el Partner Area para realizar, consultar y gestionar reservas de alquiler de vehículos de
			Centauro Rent a Car, así como para consultar y gestionar las comisiones, facturas y documentación derivadas
			de las mismas.El Partner deberá usar el Partner Area, sus servicios y contenidos de forma diligente,
			asumiendo cualquier responsabilidad que pudiera derivarse de un mal uso, incluyendo la posible revelación no
			autorizada de datos o información, así como del incumplimiento de las Condiciones de Uso o cualquier
			obligación contractual o legal vigente.El Partner únicamente podrá utilizar el Partner Area para uso propio
			y directo, absteniéndose de realizar cualquier acto que, directa o indirectamente, suponga un uso o
			explotación del Partner Area por terceros no autorizados expresa y previamente por Centauro Rent a Car.En
			particular, el Partner será responsable de cualquier actuación realizada en el Partner Area con sus datos o
			medios de acceso, así como de la integridad y exactitud de los datos e información que incorpore al Partner
			Area. Lo anterior incluye, específicamente, las reservas de alquiler de vehículos que se soliciten.
		</p>
		<h3>4.- Seguridad</h3>
		<p>
			Centauro Rent a Car adopta medidas de seguridad adecuadas para detectar la existencia de virus informáticos.
			No obstante, debido a que las medidas de seguridad de los sistemas informáticos en Internet no son
			enteramente fiables, Centauro Rent a Car no puede garantizar la inexistencia de virus u otros elementos
			dañinos para los sistemas informáticos del Partner.El Partner utilizará los contenidos y servicios
			facilitados por Centauro Rent a Car de conformidad con la legislación vigente, y asumirá las
			responsabilidades que le corresponda por las conductas o actividades que, en cualquier forma, puedan
			resultar ilícitas o lesivas para derechos de terceros o que puedan perjudicar o impedir la utilización de
			este sitio web a Centauro Rent a Car o a otros usuarios.Así mismo, el Partner no podrá utilizar el Partner
			Area para transmitir, almacenar, divulgar promover o distribuir datos o contenidos que sean portadores de
			virus o cualquier otro código informático, archivos o programas diseñados para interrumpir, destruir o
			perjudicar el funcionamiento de cualquier programa o equipo informático o de telecomunicaciones.
		</p>
		<h3>5.- Responsabilidad</h3>
		<p>
			Centauro Rent a Car no será responsable en caso de que existan interrupciones del servicio, demoras,
			errores, mal funcionamiento del mismo y, en general, demás inconvenientes que tengan su origen en causas que
			escapan del control de esta empresa, y/o debida a una actuación dolosa o culposa del Partner y/o tenga por
			origen causas de fuerza mayor. Se entenderán incluidos en el concepto de fuerza mayor, todos aquellos
			acontecimientos acaecidos fuera del control de Centauro Rent a Car, tales como y a título enunciativo y no
			exhaustivo: incidencias técnicas, fallo de terceros, operadores o compañías de servicios, actos de gobierno,
			falta de acceso a redes de terceros, actos u omisiones de las autoridades públicas, aquellos producidos como
			consecuencia de fenómenos naturales, apagones, etc. y ataques a la seguridad o integridad del sistema
			informático.Centauro Rent a Car no asumirá responsabilidad alguna ya sea por daños directos o indirectos,
			daño emergente y/o por lucro cesante, y tendrá derecho, sin que exista indemnización alguna al Partner por
			estos conceptos, a suspender temporalmente los servicios y contenidos del Partner Area para efectuar
			operaciones de mantenimiento, mejora o reparación de los mismos.Exceptuando los casos en los que que la ley
			imponga expresamente lo contrario, y exclusivamente con la medida y extensión en que lo imponga, Centauro
			Rent a Car no garantiza ni asume responsabilidad alguna sobre los datos, contenidos, información y servicios
			que se incorporan y ofrecen desde el Partner Area, así como respecto a los posibles daños y perjuicios
			causados por la utilización de la información, datos y servicios del Partner Area.
		</p>
		<h3>6.- Propiedad industrial e intelectual</h3>
		<p>
			El Partner Area y su contenido son titularidad exclusiva de Centauro Rent a Car. El acceso, navegación y uso
			del Partner Area por los socios comerciales y colaboradores de Centauro Rent a Car no supone la concesión de
			derecho alguno sobre el mismo.Los signos distintivos (gráficos y denominativos) que aparecen en este sitio
			web son propiedad exclusiva de sus respectivos titulares legítimos. En consecuencia, queda prohibida su
			utilización por parte de terceros que carezcan de la debida autorización.Los contenidos, textos,
			fotografías, diseños, logotipos, imágenes, sonidos, vídeos, animaciones, grabaciones, programas de
			ordenador, códigos fuente y, en general, cualquier creación intelectual existente en este sitio web están
			protegidos bajo derechos de autor por la legislación vigente en materia de propiedad intelectual.Queda
			prohibida la reproducción, almacenamiento (excepto aquel necesario para la carga de este sitio web en el
			navegador o almacenamiento temporal), transformación, distribución, comunicación pública, puesta a
			disposición del público y en general cualquier otra forma de explotación, por cualquier medio o
			procedimiento, de todo o parte de los contenidos sujetos a derechos exclusivos de Centauro Rent a Car,
			excepto en el caso de mediar autorización previa y expresa al efecto.Centauro Rent a Car se reserva todos
			los derechos sobre los contenidos, información datos y servicios. Centauro Rent a Car no concede ninguna
			licencia o autorización de uso al Partner sobre sus contenidos, datos o servicios distinta de la que
			expresamente se detalla en las presentes Condiciones de Uso.
		</p>
		<h3>7.- Protección de datos</h3>
		<p>
			Cualquier tratamiento de datos de carácter personal que se realice como consecuencia del acceso, navegación
			y uso del Partner Area se regulará por los acuerdos específicos establecidos entre Centauro Rent a Car y el
			Partner, aplicándose de forma subsidiaria y complementaria la Política de Privacidad del sitio web:{' '}
			<a href="https://www.centauro.net/politica-de-privacidad/" rel="noreferrer" target="_blank">
				https://www.centauro.net/politica-de-privacidad/
			</a>
		</p>
		<h3>8.- Confidencialidad</h3>
		<p>
			El Partner se compromete a mantener la confidencialidad y a no divulgar la información contenida u obtenida
			por el acceso, navegación y uso del Partner Area, salvo: (i) en cumplimiento de una obligación legal o de
			una orden administrativa o judicial; o (ii) para exigir o permitir el cumplimiento de derechos u
			obligaciones, o (iii) para, de forma restrictiva, informar a sus asesores o auditores, siempre que ambos se
			comprometan a mantenerla confidencial conforme a sus normas profesionales.
		</p>
		<h3>9.- Condiciones Generales</h3>
		<p>
			Sin perjuicio de lo establecido en las presentes Condiciones de Uso, serán de aplicación de forma
			subsidiaria y complementaria todas las Políticas, Condiciones Comerciales y Condiciones Generales de
			Centauro Rent a Car disponibles en la web{' '}
			<a href=" www.centauro.net" target="_blank">
				{' '}
				www.centauro.net
			</a>
			.
		</p>
		<h3>10.- Legislación aplicable y jurisdicción competente</h3>
		<p>
			Las presentes Condiciones de Uso se rigen y se interpretan de acuerdo con las leyes de España. Para
			cualquier reclamación serán competentes los Juzgados y Tribunales de Benidorm.
		</p>
	</>
);
export const PrivacyPolicy: FC<{ locale: string }> = ({ locale }): JSX.Element => (
	<div className="PrivacyPolicy px-2">
		<div className="modal-body">{locale === 'en' ? privacyPolicyEN : privacyPolicyES}</div>
	</div>
);
