import { useCallback, useEffect } from 'react';

import { t } from '@lingui/macro';
import { type Action, type Dispatch, bindActionCreators } from '@reduxjs/toolkit';

import { gCSwal } from '@crac/components/external/sweet-alert';
import type { ILoaderParams } from '@crac/core/models/serviceParams/LoaderParams';
import { branchGetAll, branchGetByPartnerCodeAndType } from '@crac/core/redux/actions/BranchActions';
import { countryGetAll } from '@crac/core/redux/actions/CountryActions';
import { languageGetAll } from '@crac/core/redux/actions/LanguageActions';
import { providerGetAll } from '@crac/core/redux/actions/ProviderActions';

import { useAppDispatch } from '~/redux/hooks';
import { useCountrySelector } from '~/redux/selectors/CountrySelectors';
import { useLanguagesSelector } from '~/redux/selectors/LanguagesSelectors';
import { usePartnerSelector } from '~/redux/selectors/PartnerSelectors';
import { useProviderSelector } from '~/redux/selectors/ProviderSelectors';

const mapDispatchToProps = (dispatch: Dispatch<Action>) =>
	bindActionCreators(
		{
			branchGetAll,
			branchGetByPartnerCodeAndType,
			countryGetAll,
			languageGetAll,
			providerGetAll,
		},
		dispatch,
	);

export const useLoadCacheData = () => {
	const dispatch = useAppDispatch();
	const { branchGetAll, branchGetByPartnerCodeAndType, countryGetAll, languageGetAll, providerGetAll } =
		mapDispatchToProps(dispatch);

	const { countriesGetAllRequest } = useCountrySelector();
	const partner = usePartnerSelector();
	const { languagesRequest } = useLanguagesSelector();
	const { providersRequest } = useProviderSelector();

	const handleLoadCacheData = useCallback(() => {
		if (!partner) {
			return;
		}
		const loader: ILoaderParams = { active: false };

		branchGetAll({ loader });
		branchGetByPartnerCodeAndType({
			code: partner.userName,
			loader,
			type: partner.partnerType,
		});
		providerGetAll({ loader });
		countryGetAll({ loader });
		languageGetAll({ loader });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [partner]);

	// CHECK REQUEST ERRORS
	useEffect(() => {
		const errors = [...languagesRequest.messages, ...countriesGetAllRequest.messages, ...providersRequest.messages];

		const handleShowError = async () => {
			await gCSwal(
				t`Error`,
				t`Some error happened trying to log into the system. Please, log out and log in again.`,
				'error',
			);
		};

		if (errors.length > 0) {
			handleShowError();
		}
	}, [countriesGetAllRequest.messages, languagesRequest.messages, providersRequest.messages]);

	return {
		handleLoadCacheData,
	};
};
