import React from 'react';

import { Trans } from '@lingui/macro';
import { styled } from 'styled-components';

const FooterContent = styled.footer`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
	padding: 0 1rem;
	color: #4f5d73;

	/* background: #ebedef; */
	/* border-top: 1px solid #d8dbe0; */
`;

export const Footer = (): JSX.Element => {
	return (
		<FooterContent className="text-center">
			&copy; Copyright {new Date().getFullYear()}
			<a href="https://centauro.net" rel="noopener noreferrer" target="_blank">
				&nbsp;Centauro Rent A Car&nbsp;
			</a>
			<Trans>All rights reserved.</Trans>
		</FooterContent>
	);
};
