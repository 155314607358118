import { createSlice } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { managerLocalStorage } from '@crac/core/helpers/reducerStorage';
import type { ILanguage } from '@crac/core/models/entities/Language';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import { languageGetAll } from '@crac/core/redux/actions/LanguageActions';
import type { ILanguageReducerState } from '@crac/core/redux-store/reducersState/language';

export const languagesStorageKey = 'languages';
const { setItem, getItem, removeItem } = managerLocalStorage();

const initialState: ILanguageReducerState = {
	languages: (getItem(languagesStorageKey) as ILanguage[]) || [],
	languagesRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const languageSlice = createSlice({
	name: 'LanguageSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR CACHE
		builder.addCase(clearGlobalCache, () => {
			removeItem(languagesStorageKey);
			return { ...initialState, languages: [] };
		});
		// GET ALL
		builder
			.addCase(languageGetAll.pending, (state) => {
				return {
					...state,
					languagesRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(languageGetAll.fulfilled, (state, action) => {
				setItem(languagesStorageKey, action.payload);
				return {
					...state,
					languages: deepCopy(action.payload),
					languagesRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(languageGetAll.rejected, (state, action) => {
				return {
					...state,
					languagesRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
	},
});

export const languageReducer = languageSlice.reducer;
