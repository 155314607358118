import { createSlice } from '@reduxjs/toolkit';

import { emailSendDocument } from '@crac/core/redux/actions/EmailActions';
import type { IEmailsReducerState } from '@crac/core/redux-store/reducersState/emails';

const initialState: IEmailsReducerState = {
	sendCouponByCustomerCodeRequest: { inProgress: false, messages: [], ok: true },
	sendDocumentRequest: { inProgress: false, messages: [], ok: false },
	sendFeedBackRequest: { inProgress: false, messages: [], ok: true },
};

const emailsSlice = createSlice({
	name: 'EmailsSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// SEND DOCUMENT
		builder
			.addCase(emailSendDocument.pending, (state) => {
				return {
					...state,
					sendDocumentRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(emailSendDocument.fulfilled, (state) => {
				return {
					...state,
					sendDocumentRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(emailSendDocument.rejected, (state, action) => {
				return {
					...state,
					sendDocumentRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const emailsReducer = emailsSlice.reducer;
