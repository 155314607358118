export enum PartnerEndPoints {
	/**
	 * Partner login
	 * @host `/partner/login/`
	 */
	LOGIN = '/partner/login/',

	/**
	 * Partner login
	 * @host `/partner/autologin/`
	 */
	AUTO_LOGIN = '/partner/autologin/',

	/**
	 * Partnerslogout
	 * @host `/partner/logout/`
	 */
	LOGOUT = '/partner/logout/',
	/**
	 * Partner validate
	 * @host `/partner/validate/`
	 */
	VALIDATE = '/partner/validate/',
	/**
	 * Partner get unit time
	 * @host `/partner/getUnitTimeByPartner/`
	 */
	GET_UNIT_TIME_BY_PARTNER = '/partner/getUnitTimeByPartner/',
}
