import type { FC } from 'react';

import { Navigate } from 'react-router-dom';

import { usePartnerSelector } from '~/hooks/useStateSelectors';

import { Layout } from '../Layout';

export const PrivateRoute: FC = () => {
	const partner = usePartnerSelector();

	const tokenExist = localStorage.getItem('token') && partner?.token;
	const partnerExist = localStorage.getItem('partner') && partner;

	const tokenIsExpired = partnerExist && new Date(partner.expiredDateTime).getTime() < Date.now();

	if (tokenExist && partnerExist && !tokenIsExpired) {
		return <Layout />;
	}

	return <Navigate to="/login" />;
};
