import { createSlice } from '@reduxjs/toolkit';

import { franchiseLineGetByBooking } from '@crac/core/redux/actions/FranchiseLineActions';
import type { IFranchiseReducerState } from '@crac/core/redux-store/reducersState/franchise';

const initialState: IFranchiseReducerState = {
	franchiseLineRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	franchiseLines: [],
	franchises: [],
	links: [],
	franchiseLineRepairs: [],
	franchiseLineSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	franchiseLinesToLink: [],
};

const franchiseSlice = createSlice({
	name: 'FranchiseSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// GET BY BOOKING
		builder
			.addCase(franchiseLineGetByBooking.pending, (state) => {
				return {
					...state,
					franchiseLineRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
					franchiseLines: [],
				};
			})
			.addCase(franchiseLineGetByBooking.fulfilled, (state, action) => {
				return {
					...state,
					franchiseLineRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					franchiseLines: action.payload,
				};
			})
			.addCase(franchiseLineGetByBooking.rejected, (state, action) => {
				return {
					...state,
					franchiseLineRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const franchiseReducer = franchiseSlice.reducer;
